import { createSelector } from 'reselect';
import intersection from 'lodash/intersection';

import getSelectedChoicesSelector from '../getSelectedChoicesSelector';
import choiceSkusSelector from './choiceSkusSelector';

export const productSkusSelector = createSelector(
  getSelectedChoicesSelector,
  choiceSkusSelector,
  (selectedChoices, choiceSkus) => {
    const selectedIds = selectedChoices.map((c) => c.choiceId);

    const allPotentialSkus = selectedIds.map((id) => choiceSkus[id]);
    const matchingSkus = intersection(...allPotentialSkus);

    return matchingSkus;
  },
);

export default productSkusSelector;
