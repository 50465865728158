import ModalWithHeader from '../../../../../components/ModalWithHeader';
import React,{lazy} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tiles from './Tiles';

const SamplesLazy = lazy(() => import(
  /* webpackChunkName: "samples-lazy" */
  './SampleLayout'
).then((mod) => {
  return {
    default: (props) => {
      const { SampleLayout: SampleLayoutComp } = mod;

      return (

        <SampleLayoutComp {...props} />

      );
    }
  };
}));

class SampleButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  showModal = () => {
    this.setState({
      showModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  render() {
    const {
      storeId,
      productId,
       configs
    } = this.props;

    return (
      <div>
        <PromptContainer
          role="button"
          id="colorSampleBtn"
          onClick={() => this.showModal()}
          tabIndex="0"
        >
          <Tiles width="75px" height="50px" />
          <SamplesPrompt>
            <b>Color samples</b>
            {' '}
from homedepot.com
          </SamplesPrompt>
        </PromptContainer>
        {this.state.showModal && (
          <ModalWithHeader header="Order Samples" onClose={this.closeModal}>
            <SamplesLazy
              storeId={storeId}
              productId={productId}
              configs = {configs}
              closeModal={this.closeModal}
            />
          </ModalWithHeader>
        )}
      </div>
    );
  }
}

SampleButton.propTypes = {
  storeId: PropTypes.number.isRequired,
  productId: PropTypes.number.isRequired
};

export default SampleButton;

const PromptContainer = styled.div`
  padding: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  max-width: 200px;
`;

const SamplesPrompt = styled.span`
  padding-left: 5px;
  padding-top: 5px;
  font-size: 15px;
  color: #3e7697;
`;
