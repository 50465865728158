import createReducer from './createReducer';
import {
  HARDWARE_SUBAPP_SET_DATA,
  HARDWARE_SUBAPP_SELECTED_PRODUCT,
  HARDWARE_SUBAPP_PRODUCT_SELECTION,
} from '../actions';

import selectProduct from './selectProduct';
import changeProductSelections from './changeProductSelections';

export const reducer = createReducer(
  {
    products: {},
    options: {},
    choices: {},
    skuChoices: {},
    choiceSkus: {},
    selected: [],
    selections: {},
  },
  {
    [HARDWARE_SUBAPP_SET_DATA](state, action) {
      return { ...state, ...action.data };
    },
    [HARDWARE_SUBAPP_SELECTED_PRODUCT]: selectProduct,
    [HARDWARE_SUBAPP_PRODUCT_SELECTION]: changeProductSelections,
  },
);

export default reducer;
