import getWindowLocation from './getWindowLocation';

export default () => {
  const url = getWindowLocation();
  if (url) {
    const [, params] = url && url.split('?');
    if (params) {
      const paramParts = params && params.split('&');
      const lineItemParam = paramParts.find((p) => p.indexOf('lineItem') !== -1);
      if (lineItemParam) {
        const [, value] = lineItemParam && lineItemParam.split('=');
        return value && value.split('_')[0];
      }
    }
  }
  return '';
};
