import React from 'react';
import PropTypes from 'prop-types';
import * as S from './Styled.HardwareDescriptionContent';

const HardwareDescriptionContent = ({ type }) => {
  if (type === 'Grooved') {
    return (
      <S.HardwareDescriptionContent>
        Hidden fasteners and starter clips are required to install grooved edge decking boards. We have
        calculated the amount of hardware you need based on the quantity above.
      </S.HardwareDescriptionContent>
    );
  }

  return (
    <S.HardwareDescriptionContent>
      Screws are required to install squared edge decking boards. We have calculated the amount of hardware you
      need based on the quantity above.
    </S.HardwareDescriptionContent>
  );
};

HardwareDescriptionContent.propTypes = {
  type: PropTypes.string.isRequired,
};

export default HardwareDescriptionContent;
