import React from 'react';
import { connect } from 'react-redux';
import Proptypes from 'prop-types';
import styled from 'styled-components';
import {
  Row, Column, GrowthGroup, HorizontalBar,
} from './SharedLayoutStyles';
import config from '../../../../config';

import redirectTo from '../../utils/redirectTo';
import { selectedProductSkuDataSelector } from '../../selectors/catalog';

export const ResurfacerCallout = (props) => {

  if (!props.showingSupportedBrand) return null;

  return (
    <div>
      <HorizontalBar />
      <Row>
        <GrowthGroup>
          <Column>
            <PromptHeader>Customizing a deck?</PromptHeader>
            <PromptBody>
              Use our decking calculator to replace deck boards or build a new deck
            </PromptBody>
          </Column>
        </GrowthGroup>
        <GrowthGroup>
          <CalloutButton
            role="button"
            tabIndex="0"
            onClick={() => redirectTo(`${props.resurfacerUrl}?sku=${props.selectedProductSkuData.sku}`)}
          >
            Customize Your Deck
          </CalloutButton>
        </GrowthGroup>
      </Row>
      <HorizontalBar />
    </div>
  );
};

ResurfacerCallout.propTypes = {
  showingSupportedBrand: Proptypes.bool.isRequired,
  resurfacerUrl: Proptypes.string,
  selectedProductSkuData: Proptypes.object.isRequired,
};

ResurfacerCallout.defaultProps = {
  resurfacerUrl: '',
};

const mapStateToProps = (state) => {
  const { resurfacerUrl, supportedBrands } = config;
  const { brand } = state.configurator.productInfo;
  const showingSupportedBrand = supportedBrands && brand
    && supportedBrands.toLowerCase().indexOf(brand.toLowerCase()) !== -1;
  const selectedProductSkuData = selectedProductSkuDataSelector(state);
  return {
    resurfacerUrl,
    showingSupportedBrand,
    selectedProductSkuData,
  };
};

export default connect(mapStateToProps)(ResurfacerCallout);

export const CalloutButton = styled.div`
  margin-left: 30px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  color: #f96302;
  cursor: pointer;
  border-style: solid;
  border-width: 3px;
  border-color: #f96302;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  white-space: nowrap;
   @media(max-width: 426px) {
    margin-left: 10px;
    padding-left: 11px;
    padding-right: 11px;
  }
`;

const PromptHeader = styled.span`
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
`;
const PromptBody = styled.span`
  font-size: 15px;
  line-height: 17px;
`;
