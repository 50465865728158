import { createAction } from './createAction';

export const CATALOGS_RECEIVED_SUCCESS = 'CATALOGS_RECEIVED_SUCCESS';

export const catalogsReceivedSuccess = createAction(
  CATALOGS_RECEIVED_SUCCESS,
  'catalogData',
);

export const UPDATE_CATALOGS = 'UPDATE_CATALOGS';

export const updateCatalogs = createAction(
  UPDATE_CATALOGS,
  'storeId',
  'qcCatalog',
  'thdCatalog',
  'fulfillmentType',
);

export const GET_CATALOG = 'GET_CATALOG';
export const getCatalog = createAction(
  GET_CATALOG,
  'decksApiUrl',
  'storeId',
  'productId',
  'externalId',
);

export const SET_CATALOG = 'SET_CATALOG';
export const setCatalog = createAction(SET_CATALOG, 'skuChoices', 'choiceSkus');

export const CATALOG_READY = 'CATALOG_READY';
export const catalogReady = createAction(CATALOG_READY);

export const INIT_DECKING = 'INIT_DECKING';
export const initDecking = createAction(INIT_DECKING);

export const SET_CONFIG_SETTINGS = 'SET_CONFIG_SETTINGS';
export const setConfigSettings = createAction(SET_CONFIG_SETTINGS, 'settings');
