import styled from 'styled-components';

export const HardwareProduct = styled.div`
  height: inherit;
`;

export const NoMatchErrorContainer = styled.div`
  display: flex;
  margin-bottom: 28px;
`;

export const NoMatchErrorContentContainer = styled.div`
  padding-left: 11px;
  font-size: 14px;
  color: #8f6e2e;
`;

export const NoMatchErrorTitle = styled.h5`
  font-weight: bold;
  font-size: 14px;
`;
