import memoize from 'lodash/memoize';
import isEmpty from 'lodash/isEmpty';
import intersection from 'lodash/intersection';
import { choiceSkusSelector } from '../../selectors/catalog';
import {
  getSelectedDimension,
  getSelectedPrimaryColor,
} from '../../utils/getSelectedChoices';
import { getSelectedChoicesSelector } from '../../selectors';

const getDominantChoicesforEdge = (state) => {
  const selectedChoices = getSelectedChoicesSelector(state);
  const dim = getSelectedDimension(selectedChoices)[0];
  const color = getSelectedPrimaryColor(selectedChoices)[0];

  const ids = [];
  if (dim) ids.push(dim.choiceId);
  if (color) ids.push(color.choiceId);

  return ids;
};

const edgeIsFulfillable = (choiceSkus, edgeChoiceId) => !isEmpty(choiceSkus[edgeChoiceId]);

const edgeAvailabilityEvalutorFactory = (state) => (edgeChoiceId) => {
  const choiceSkus = choiceSkusSelector(state);
  if (edgeIsFulfillable(choiceSkus, edgeChoiceId)) {
    const dominantChoiceIds = getDominantChoicesforEdge(state);

    const allPossibleSkus = [edgeChoiceId, ...dominantChoiceIds].map((chId) => choiceSkus[chId]);

    const matchingSkus = intersection(...allPossibleSkus);
    const hasMatchingSkus = matchingSkus.length > 0;
    return hasMatchingSkus;
  }
  return false;
};

export default memoize(edgeAvailabilityEvalutorFactory);
