import { SIX_INCH_BOARD_FOOTAGE, FOUR_INCH_BOARD_FOOTAGE, EIGHT_INCH_BOARD_FOOTAGE } from './constants';

export function estimateSqft({ width, length }, quantity) {
  const widthToDivisor = {
    6: SIX_INCH_BOARD_FOOTAGE,
    4: FOUR_INCH_BOARD_FOOTAGE,
    8: EIGHT_INCH_BOARD_FOOTAGE
  };

  if (widthToDivisor[width] === undefined) {
    return 0;
  }

  const totalLinearFeet = length * quantity;
  return Math.floor(totalLinearFeet / widthToDivisor[width]);
}

export function estimateCost(price, quantity) {
  return Number(`${Math.round(`${price * quantity}e2`)}e-2`);
}
