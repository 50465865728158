import Api from './axios';

export const getProductOmsId = (omsApiUrl, productId, mVendor) => {
  const url = `${omsApiUrl}/${productId}/${mVendor}`;
  const api = Api();
  Object.assign(api.defaults, { crossDomain: true });
  return api.get(url);
};

export default getProductOmsId;
