import memoize from 'lodash/memoize';
import productsSelector from '../selectors/productsSelector';
import optionsSelector from '../selectors/optionsSelector';

export const productsWithColorOptionProjector = (state) => {

  const products = productsSelector(state);

  const options = optionsSelector(state);

  const colorOptions = Object.keys(options).filter((oId) => options[oId].optionType && options[oId].optionType.toLowerCase() === 'primary color').map((id) => Number(id));

  const coloredProducts = Object.keys(products).filter((pId) => products[pId].options.filter((o) => !o.hidden).map((option) => option.id).some((o) => colorOptions.includes(o)));

  const productsWithColorOption = coloredProducts.map((pId) => {
    const theProduct = products[pId];
    return {
      productId: pId,
      colorOptionId: theProduct.options.map((option) => option.id).find((o) => colorOptions.includes(o))
    };
  });

  return productsWithColorOption;
};

export default memoize(productsWithColorOptionProjector);
