// @ts-check

import { createSelector } from 'reselect';
import { getSelectedChoicesSelector } from './getSelectedChoicesSelector';
import { getSelectedEdge } from '../utils/getSelectedChoices';

export const getSelectedEdgeSelector = createSelector(
  (state) => getSelectedChoicesSelector(state),

  (selectedChoices) => {
    const edgeOption = getSelectedEdge(selectedChoices);
    if (!edgeOption) return '';
    return edgeOption[0] ? edgeOption[0].internalName : '';
  },
);

export default getSelectedEdgeSelector;
