import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const HardwareDescription = styled.div`

`;

export const HardwareDescriptionHeader = styled.h3`
  font-size: 18px;
  margin-bottom: 12px;
`;
