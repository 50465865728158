import { getSelectedEdgeSelector, quantitySelector } from '../selectors';
import assemblyTypes from '../utils/assemblyTypes';
import { childAssemblyByTypeSelectorFactory } from '../selectors/assembly';
import { getSelectedStore } from '../utils/getSelectedStoreInfo';
import getSelectedColorProjector from './getSelectedColorProjector';
import getSelectedChoicesSelector from '../selectors/getSelectedChoicesSelector';
import { getBoardAreaSelector } from '../selectors/getBoardAreaSelector';
import { availableStockSelector } from '../selectors/catalog/availableStockSelector';
import getConfiguratorSettings from '../selectors/configuratorSettings';
import injectedConfiguratorSettings from '../../../config';

const getBoards = (selectedDimension, quantity) => {
  const boards = [];
  selectedDimension.forEach((dimension) => {
    boards.push({ size: dimension.internalName.split('_')[1], count: quantity });
  });
  return boards;
};

const hardwareAssemblyProjector = (state) => {
  const config = { ...injectedConfiguratorSettings };
  const selectedEdge = getSelectedEdgeSelector(state);
  const selectedColor = getSelectedColorProjector(state);
  const { storeId } = getSelectedStore();
  const hardwareAssembly = childAssemblyByTypeSelectorFactory(assemblyTypes.HARDWARE)(state);
  const decksApiUrl = getConfiguratorSettings(state)?.decksApiUrl || config?.decksApiUrl;
  const selectedDimension = getSelectedChoicesSelector(state).filter((selection) => (selection.optionType === 'DimensionxLength'));
  const quantity = quantitySelector(state);
  const boards = getBoards(selectedDimension, quantity);
  const deckArea = getBoardAreaSelector(state);
  const availableStock = availableStockSelector(state);

  return {
    selectedEdge,
    assemblyId: +hardwareAssembly.assemblyId,
    storeId: +storeId,
    decksApiUrl,
    selectedColor,
    boards,
    deckArea,
    availableStock,
  };
};

export default hardwareAssemblyProjector;
