import memoize from 'lodash/memoize';
import isEmpty from 'lodash/isEmpty';
import intersection from 'lodash/intersection';
import { choiceSkusSelector } from '../../selectors/catalog';
import { getSelectedDimension } from '../../utils/getSelectedChoices';
import { getSelectedChoicesSelector } from '../../selectors';

const colorIsFulfillable = (choiceSkus, colorChoiceId) => !isEmpty(choiceSkus[colorChoiceId]);

const getDominantChoicesforColor = (state) => {
  const selectedChoices = getSelectedChoicesSelector(state);
  const dim = getSelectedDimension(selectedChoices)[0];

  const ids = [];
  if (dim) ids.push(dim.choiceId);

  return ids;
};

const colorAvailabilityEvalutorFactory = (state) => (colorChoiceId) => {
  const choiceSkus = choiceSkusSelector(state);
  if (colorIsFulfillable(choiceSkus, colorChoiceId)) {
    const dominantChoiceIds = getDominantChoicesforColor(state);

    const allPossibleSkus = [colorChoiceId, ...dominantChoiceIds].map((chId) => choiceSkus[chId]);
    const matchingSkus = intersection(...allPossibleSkus);
    const hasMatchingSkus = matchingSkus.length > 0;
    return hasMatchingSkus;
  }
  return false;
};

export default memoize(colorAvailabilityEvalutorFactory);
