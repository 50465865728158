import { createReducer } from '../utils';
import { SET_CATALOG } from '../actions';

const onSetCatalog = (state, { skuChoices, choiceSkus }) => ({ ...state, skuChoices, choiceSkus });

export const catalog = createReducer(
  {},
  {
    [SET_CATALOG]: onSetCatalog,
  },
);

export default catalog;
