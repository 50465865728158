import { put } from 'redux-saga/effects';
import {
  setMediaGalleryImagesFailed,
  GET_MEDIA_GALLERY_IMAGES_FAILED,
} from '../../actions';
import { setSagaError } from './sagaActions';

export function safeSaga(sagaGeneratorFunc) {
  // eslint-disable-next-line func-names
  return function* (args) {
    try {
      // console.log('saga args', args);
      yield* sagaGeneratorFunc(args);
    } catch (error) {
      if (sagaGeneratorFunc.name === 'visualizerImages') {
        yield put(setMediaGalleryImagesFailed(GET_MEDIA_GALLERY_IMAGES_FAILED));
      }
      yield put(setSagaError({ saga: sagaGeneratorFunc.name, args, error }));
    }
  };
}

export function safeSagaFilter(sagaFilter) {
  // eslint-disable-next-line func-names
  return function (args) {
    try {
      // console.log('Saga Filter args', args);
      return sagaFilter(args);
    } catch (error) {
      console.log(`Saga Filter ${sagaFilter.name} Error: ${error} ; Args: ${args}`);
      return false;
    }
  };
}
