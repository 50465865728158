export default (state, { productId }) => {
  const nuSelected = [...state.selected];
  const index = nuSelected.indexOf(productId);
  const entryFound = index !== -1;
  if (entryFound) {
    nuSelected.splice(index, 1);
  } else {
    nuSelected.push(productId);
  }

  return { ...state, selected: nuSelected };
};
