/**
 * Convert styled-components' dynamic styles to conventional DOM styles.
 */
export default () => {
  const el = document.createElement('style');
  el.setAttribute('data-styled-components-prerender', '');
  const styles = document.querySelectorAll('style[data-styled-components],style[data-styled]');
  Array.prototype.forEach.call(
    styles,
    ({ sheet }) => {
      Array.prototype.forEach.call(
        sheet.cssRules,
        ({ cssText }) => {
          el.appendChild(document.createTextNode(cssText));
        },
      );
    },
  );
  document.head.appendChild(el);
};
