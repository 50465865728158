import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as S from './Styled.HardwareProducts';
import {
  NoMatchErrorContainer,
  NoMatchErrorContentContainer,
  NoMatchErrorTitle
} from '../HardwareProduct/Styled.HardwareProduct';
import WarningIcon from '../Icons/WarningIcon';
import HardwareProduct from '../HardwareProduct';
import { activeProductIdsProjectorFactory } from '../../projectors/activeProductIdsProjector';
import theContext from '../../context';
import { getSelectedEdgeSelector } from '../../../../selectors';
import { SUB_APP_SELECTED_EDGE_CHANGED } from '../../../../actions';

const HardwareProducts = ({
  hardwareAssemblyProducts,
  callbackHandler,
  selectedEdge,
  disable,
  boards,
  deckArea
}) => {
  useEffect(() => {
    callbackHandler(SUB_APP_SELECTED_EDGE_CHANGED);
  }, [selectedEdge]);

  return (
    <div>
      {
        disable ? (
          <NoMatchErrorContainer>
            <WarningIcon />
            <NoMatchErrorContentContainer>
              <NoMatchErrorTitle className="availability-error">
                Our recommended hardware is currently unavailable.
              </NoMatchErrorTitle>
            </NoMatchErrorContentContainer>
          </NoMatchErrorContainer>
        ) : null
      }
      <S.HardwareProducts>
        {
          hardwareAssemblyProducts.map((calculatorDetails) => (
            <S.HardwareProductsItem key={calculatorDetails.productId}>
              <HardwareProduct
                calculatorDetails={calculatorDetails}
                context={theContext}
                callbackHandler={callbackHandler}
                disabled={disable}
                boards={boards}
                deckArea={deckArea}
              />
            </S.HardwareProductsItem>
          ))
        }
      </S.HardwareProducts>
    </div>
  );
};

HardwareProducts.propTypes = {
  hardwareAssemblyProducts: PropTypes.array.isRequired,
  callbackHandler: PropTypes.func.isRequired,
  selectedEdge: PropTypes.string.isRequired,
  disable: PropTypes.bool.isRequired,
  boards: PropTypes.array.isRequired,
  deckArea: PropTypes.object.isRequired,
};

const mapStatetoProps = (state) => {
  const edge = getSelectedEdgeSelector(state);
  const hardwareAssemblyProducts = activeProductIdsProjectorFactory(edge)(state);
  return { hardwareAssemblyProducts };
};

export default connect(mapStatetoProps)(HardwareProducts);
