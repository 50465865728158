import { getPathFromSelectedChoices } from '../../utils/pathUtils';

export const updatePath = () => (dispatch, getState) => {
  const { configurator } = getState();
  const url = getPathFromSelectedChoices(configurator);
  window.history.replaceState(
    null,
    document.title,
    url
  );
};

export default { updatePath };
