import selectedHardwarePriceSelector from '../../_SubApps/HardwareAssembly/selectors/selectedHardwarePriceSelector';

export function getTotalHardwarePriceSelector(state) {
  const subApps = state.subApps;
  const defaultPrice = 0.00;
  if (subApps) {
    let totalPrice = 0.00;
    const rootObjectKeys = Object.keys(subApps);
    rootObjectKeys.forEach((key) => {
      const objectKey = Object.values(subApps[key]);
      const priceMatch = objectKey.filter((m) => m.selected);
      priceMatch.forEach((match) => {
        if (match.pricing && match.quantity) {
          const price = selectedHardwarePriceSelector(match.productId)(state);
          totalPrice += price.price * match.quantity;
        }
      });
    });

    return (totalPrice);
  }
  return defaultPrice;
}

export default getTotalHardwarePriceSelector;
