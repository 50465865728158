import identity from 'lodash/identity';
import * as types from '../../apps/actions/actionTypes';
import updateQuantity from './updateQuantity';
import {
  HIDE_CHECK_STORE_MODAL,
  SHOW_CHECK_STORE_MODAL,
  SET_MARKET_AVAILABILITY,
} from '../../actions';

const actions = {
  [types.UPDATE_QUANTITY]: updateQuantity,
  [SET_MARKET_AVAILABILITY]: (state, action) => ({
    ...state,
    isInMarket: action.inMarket,
  }),
  [SHOW_CHECK_STORE_MODAL]: (state) => ({
    ...state,
    showCheckStoreModal: true,
  }),
  [HIDE_CHECK_STORE_MODAL]: (state) => ({
    ...state,
    showCheckStoreModal: false,
  }),
};

const initialState = {
  quantity: 1,
  isInMarket: true,
  showCheckStoreModal: false,
};

export default (state = initialState, action) => (actions[action.type] || identity)(state, action);
