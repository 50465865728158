import { setSelectedProduct } from './setSelectedProduct';
import selectedProductsSelector from '../selectors/selectedProductsSelector';
import theContext from '../context';

export const updateSelectedHardware = (productId) => (dispatch, getState) => {
  dispatch(setSelectedProduct(productId));
  const selectedProducts = selectedProductsSelector(getState());
  theContext.callback(selectedProducts);
};

export default updateSelectedHardware;
