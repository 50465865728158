import { createSelector } from 'reselect';
import { getChoicesSelector } from './getChoicesSelector';
import { getOptionsSelector } from './getOptionsSelector';

export const hiddenOptionByOptionTypeName = (optionTypeName) => createSelector(
  getOptionsSelector,
  getChoicesSelector,
  (options, choices) => {
    const selectedhiddenoption = Object.values(options).filter((option) => option.internalName === optionTypeName);
    if (selectedhiddenoption.length > 0) {
      const choice = choices[selectedhiddenoption[0].choices[0]];
      return { optionId: selectedhiddenoption[0].id, optionType: selectedhiddenoption[0].optionType, choice };
    }
    return {};
  },
);
export default hiddenOptionByOptionTypeName;
