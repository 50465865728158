// @ts-check

import { createSelector } from 'reselect';
import { getSelectedChoices } from '../utils/getSelectedChoices';

export const getSelectedChoicesSelector = createSelector(
  (state) => state.configurator,
  ({ options, choices }) => getSelectedChoices(options, choices),
);

export default getSelectedChoicesSelector;
