import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SqftEstimateStyle, CalculatedDisplayStyles } from './EstimatorStyles';

export class SqftEstimate extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { calculatedSqft } = this.props;

    return (
      <SqftEstimateStyle>
        This quantity of boards will cover up to{' '}
        <CalculatedDisplayStyles id="sqft">
          {`${calculatedSqft} sq ft`}
        </CalculatedDisplayStyles>
      </SqftEstimateStyle>
    );
  }
}

SqftEstimate.propTypes = {
  calculatedSqft: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  calculatedSqft: state.estimation.sqft,
});

export default connect(mapStateToProps)(SqftEstimate);
