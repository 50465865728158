import memoize from 'lodash/memoize';

/*
This selector is to be used as the root for all other Selectors. If you have a products Selector for the subApp, then:
product selecotr = createSelector(rootSelector, (root)=> root.products);

Eventually this selector will be provided by the parent like getState or someting similar.
This will allow the parent to mount the app in whatever reducer location they like,
    so long as they provide the means for accessing our state.
 */
export const rootSelector = memoize((state) => state.hardwareSubApp);

export default rootSelector;
