import {
  takeLatest,
  call,
  put,
  select
} from 'redux-saga/effects';

import { updatePricing } from '@gcc/configurator';
import { safeSaga } from '../utils';
import {
  GET_CATALOG,
  setCatalog,
  catalogReady,
  activateSpinnerATC,
  setMarketAvailability,

  getMediaGalleryImages
} from '../../actions';

import { getSkuCatalog } from '../../services/skuApi';
import isInMarket from '../../businessLogic/isInMarket';
import productSkusSelector from '../../selectors/catalog/productSkusSelector';

import productInfoSelector from '../../selectors/productInfoSelector';
import getConfiguratorSettings from '../../selectors/configuratorSettings';

export function* skuCatalog(action) {
  try {
    yield put(activateSpinnerATC(true));
    const {
      decksApiUrl, storeId, productId, externalId,
    } = action;
    const { data: skuData } = yield call(
      getSkuCatalog,
      decksApiUrl,
      storeId,
      productId,
      externalId,
    );
    yield put(setCatalog(skuData.skuChoices, skuData.choiceSkus));

    const isProductInMarket = isInMarket(skuData.skuChoices);
    yield put(setMarketAvailability(isProductInMarket));

    yield put(catalogReady());

    const config = yield select(getConfiguratorSettings);
    const productInfo = yield select(productInfoSelector);
    const productSkus = yield select(productSkusSelector);
    const payload = {
      ProductId: productInfo.productId,
      Sku: productSkus[0],
      Scene7Folder: config.scene7Folder,
    };
    yield put(getMediaGalleryImages(decksApiUrl, payload));
    yield put(activateSpinnerATC(false));
    yield put(updatePricing());
  } catch (err) {
    console.log(err);
  }

}

export function* skuCatalogSaga() {
  // console.log('Sku Catalog Saga Started.');
  yield takeLatest(GET_CATALOG, safeSaga(skuCatalog));
}

export default skuCatalogSaga;
