import { assert } from '../../../apps/debug/assert';

export function getFulfillmentInfo(storeNumber, items) {
  // console.log({getFulfillmentInfo: items });
  const label = (isBOPIS) => (isBOPIS ? 'BOPIS' : 'ShipToStore');
  return items.map((item) => ({
    storeNumber,
    method: label(item.isBOPIS === undefined ? item.isStock : item.isBOPIS)
  }));
}

export function makeCartableKit(items, fulfillmentInfo) {

  assert({ condition: Array.isArray(items), error: 'items is not an array', data: { items } });
  assert({ condition: Array.isArray(fulfillmentInfo), error: 'fulfillmentInfo is not an array', data: { fulfillmentInfo } });
  assert({ condition: items.length > 0, error: 'Items is an empty array', data: { items } });
  assert({
    condition: items.length === fulfillmentInfo.length,
    error: 'Items and Fulfillment info have different lengths',
    data: { items, fulfillmentInfo }
  });

  const itemDetails = items.map((item, i) => {
    const itemDetail = {};
    itemDetail.itemId = item.omsId;
    itemDetail.configurationId = item.configurationId;
    itemDetail.quantity = item.quantity;
    itemDetail.fulfillmentLocation = fulfillmentInfo[i].storeNumber;
    itemDetail.fulfillmentMethod = fulfillmentInfo[i].method;
    itemDetail.unitPrice = item.unitPrice;

    return itemDetail;
  });

  return {
    CartRequest: {
      itemDetails,
      localStoreId: fulfillmentInfo[0].storeNumber,
    },
  };
}
