const showNoMatchMessageProjector = (PrimaryColorSelectedColor, selectedColor) => {
  const { tags } = selectedColor;
  if (!tags) return false;

  if (PrimaryColorSelectedColor !== tags[0]) return true;

  return false;
};

export default showNoMatchMessageProjector;
