import productsWithColorOptionProjector from '../projectors/productsWithColorOption';
import optionsSelector from '../selectors/optionsSelector';
import choicesSelector from '../selectors/choicesSelector';
import { changeProductSelection } from '../actions';

const matchColorToChoices = (
  colorToMatch,
) => (dispatch, getState) => {
  const state = getState();
  const productsWithColorOption = productsWithColorOptionProjector(state);

  const options = optionsSelector(state);
  const choices = choicesSelector(state);

  productsWithColorOption.forEach((p) => {
    const theOption = options[p.colorOptionId];
    const relatedChoices = theOption.choices.map((cId) => choices[cId]).sort((a, b) => a.sequenceNumber - b.sequenceNumber);
    const matchingChoice = relatedChoices.find((c) => c.tags.includes(colorToMatch));
    const selectedChoice = matchingChoice || relatedChoices[0];

    dispatch(changeProductSelection(p.productId, p.colorOptionId, selectedChoice.id, selectedChoice.shortLabel));
  });
};

export default matchColorToChoices;
