import { createSelector } from 'reselect';
import { hardwareAssembliesSelectorByActiveEdge } from '../graphs/hardwareAssemblySelectors';

const getSelectedHardwareSelector = createSelector(
  (state) => state.subApps.hardware,
  (hardware) => Object.values(hardware).filter((hw) => hw.selected)
);

export function getCartableHardwareSelector(state) {
  const selected = getSelectedHardwareSelector(state);
  const assemblies = hardwareAssembliesSelectorByActiveEdge(state);
  const selectedAssemblies = assemblies.filter((assembly) => selected.filter((s) => s.productId === assembly.productId).length > 0);
  return selectedAssemblies;
}

export default getCartableHardwareSelector;
