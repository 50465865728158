import { buildBaseEventData } from '.';
import { Utils } from '../common';

const itemToThdAnalyticsEvent = (
  items,
  fulfillmentMethod = 'boss',
  logEventsToConsole = false,
) => {
  if (logEventsToConsole) {
    // console.log('itemToThdAnalyticsEvent =>', JSON.stringify(items));
  }

  const addToCartLocationEvent = buildBaseEventData(
    'stash',
    'cart add location',
  );
  addToCartLocationEvent.eventMode = 'stash';

  addToCartLocationEvent.stash = {
    pageType: 'pip',
    sharedSection: '',
    section: '',
    component: '',
    target: 'atc',
    item: items.map((i) => ({
      index: '',
      productID: i.item.sku.toString(),
    })),
  };

  const addToCartEvent = buildBaseEventData('overlay', 'cart add');

  addToCartEvent.item = items.map((i) => ({
    productInfo: {
      sku: i.item.sku.toString(),
    },
    quantity: i.item.quantity,
    price: {
      basePrice: (i.item.unitPrice * i.item.quantity).toFixed(2).toString(),
    },
    fulfillment: {
      method: (i.item.fulfillmentMethod === 'ShipToStore') ? 'boss' : (i.item.fulfillmentMethod || fulfillmentMethod),
    },
  }));

  return [addToCartLocationEvent, addToCartEvent];
};

export const toAddToCartAnalyticsEvent = (
  items,
  fulfillmentMethod,
  logEventsToConsole,
) => itemToThdAnalyticsEvent(items, fulfillmentMethod, logEventsToConsole);

export async function sendAddToCartEvent(
  items,
  fulfillmentMethod,
  logEventsToConsole,
) {
  const addToCartEvent = toAddToCartAnalyticsEvent(
    items,
    fulfillmentMethod,
    logEventsToConsole,
  );
  addToCartEvent.forEach(async (event) => {
    await Utils.sendAnalyticsEvent(event);
  });
}

const sampleItemToThdAnalyticsEvent = (items, logEventsToConsole) => {
  if (logEventsToConsole) {
    // console.log('sampleItemToThdAnalyticsEvent =>', JSON.stringify(items));
  }

  const sampleAddToCartEvent = buildBaseEventData('overlay', 'cart add');

  sampleAddToCartEvent.item = items.map((i) => ({
    productInfo: {
      sku: i.item.sku.toString(),
      productCategory: '',
      productType: 'sample',
      option: {
        color: i.item.name,
        price: 0,
      },
      manufacturer: '',
    },
    quantity: i.item.quantity,
    price: {
      basePrice: (i.item.unitPrice * i.item.quantity).toFixed(2).toString(),
    },
    fulfillment: {
      method: 'sth',
    },
  }));

  return [sampleAddToCartEvent];
};

export const sendSampleAddToCartEvent = (items, logEventsToConsole) => {
  const sampleAddToCartEvent = sampleItemToThdAnalyticsEvent(
    items,
    logEventsToConsole,
  );
  sampleAddToCartEvent.forEach((event) => Utils.sendAnalyticsEvent(event));
};
