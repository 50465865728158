import React from 'react';
import PropTypes from 'prop-types';
import { Configurator } from '@gcc/configurator';
import getConfiguratorSettings from '../../utils/getConfiguratorSettings';
import Layout from '../templates/Layout';
import injectedConfiguratorSettings from '../../../../config';
import useConfiguratorDataFromGraph from '../hooks/useConfiguratorDataFromGraph';
import getOmsIdFromUrl from '../../../../utils/getOmsIdFromUrl';
import isDraftProduct from '../../../../utils/isDraftProduct';

const omsIdFromUrl = getOmsIdFromUrl;

const PIP = (props) => {

  const config = { ...injectedConfiguratorSettings };
  const {
    onLoadSuccess, updatePricing, partnerProductId, productDelivery, storeId, zipCode, boardPrice
  } = props;
  const configuratorSettings = getConfiguratorSettings({ onLoadSuccess, config, updatePricing });

  useConfiguratorDataFromGraph({ productDelivery, partnerProductId: isDraftProduct ? omsIdFromUrl : partnerProductId, storeId, zipCode });

  return (
    <>
      <div className="buybox" data-section="buybox">
        <div className="buybox__super-sku">
          <Configurator settings={configuratorSettings}>
            <Layout />
          </Configurator>
        </div>
      </div>
    </>
  );
};

PIP.defaultProps = {
};

PIP.propTypes = {
  updatePricing: PropTypes.func.isRequired,
  partnerProductId: PropTypes.string.isRequired,
  productDelivery: PropTypes.string.isRequired,
  storeId: PropTypes.string.isRequired,
  zipCode: PropTypes.number.isRequired,
  onLoadSuccess: PropTypes.func.isRequired,
  boardPrice: PropTypes.number.isRequired,
};

export default PIP;