import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { reducer } from '../reducers';
import { initSagas } from './initsagas';

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ )|| compose; // eslint-disable-line

const configureStore = (initialState = {}) => {
  const store = createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware, thunk)),
  );
  initSagas(sagaMiddleware);

  return store;
};

export default configureStore;
