/* eslint-disable no-param-reassign */
import { createReducer } from '../utils';
import {
  SET_HARDWARE,
  UPDATE_SELECTED_HARDWARE,
  UPDATE_HARDWARE_CONFIG,
  UPDATE_HARDWARE_QUANTITY,
  UPDATE_HARDWARE_COST,
  SET_HARDWARE_CONFIGURATIONS,
} from '../actions';
import getHiddenOptionIds from '../utils/hardware/getHiddenOptionIds';

export const onSetHardware = (state, { hardwareList }) => {
  const products = hardwareList.reduce((dict, hw) => {
    dict[hw.productId] = hw;
    return dict;
  }, {});
  const ids = hardwareList.map((hw) => hw.productId);

  const configurations = {};

  return {
    ...state,
    hardwareProducts: products,
    selected: ids,
    allIds: ids,
    estimates: {},
    configurations,
  };
};

export const onUpdateConfiguration = (
  state,
  {
    hardwareId, optionId, choiceId, choiceValue,
  },
) => {
  const updatedConfigs = { ...state.configurations };

  const alteredConfig = { ...updatedConfigs[hardwareId] };
  alteredConfig[optionId] = { choiceId, choiceValue };
  updatedConfigs[hardwareId] = alteredConfig;

  return { ...state, configurations: updatedConfigs };
};

export const onUpdateHardwareQuantity = (
  state,
  { hardwareId, newQuantity },
) => {
  const newEstimates = { ...state.estimates };
  const updatedEstimate = { ...newEstimates[hardwareId] };
  updatedEstimate.quantity = newQuantity;
  newEstimates[hardwareId] = updatedEstimate;
  return { ...state, estimates: newEstimates };
};

const updateChoiceValue = (config, optionId, newChoiceValue) => {
  config[optionId] = Object.assign(config[optionId] || {}, {
    choiceValue: newChoiceValue,
  });
};

const updateHiddenHwOptions = (
  state,
  config,
  vendorNumber,
  externalId,
  storeId,
  vendorPartNumber,
) => {
  const {
    vendorOptionId,
    storeOptionId,
    productOptionId,
    partNumberOptionId,
  } = getHiddenOptionIds(state);

  updateChoiceValue(config, vendorOptionId, vendorNumber);
  updateChoiceValue(config, storeOptionId, storeId);
  updateChoiceValue(config, productOptionId, externalId);
  updateChoiceValue(config, partNumberOptionId, vendorPartNumber);

  return config;
};

export const onUpdateHardwareCost = (
  state,
  {
    hardwareId,
    newCost,
    vendorNumber,
    unitPrice,
    externalId,
    storeId,
    vendorPartNumber,
  },
) => {
  const updatedEstimates = { ...state.estimates };
  const alteredEstimate = { ...updatedEstimates[hardwareId] };

  alteredEstimate.price = newCost;
  alteredEstimate.unitPrice = unitPrice;
  updatedEstimates[hardwareId] = alteredEstimate;

  const updatedConfigs = { ...state.configurations };
  let alteredConfig = { ...updatedConfigs[hardwareId] };

  alteredConfig = updateHiddenHwOptions(
    state,
    alteredConfig,
    vendorNumber,
    externalId,
    storeId,
    vendorPartNumber,
  );
  updatedConfigs[hardwareId] = alteredConfig;

  return {
    ...state,
    estimates: updatedEstimates,
    configurations: updatedConfigs,
  };
};

export const onSetHardwareConfigurations = (state, { configurations }) => ({
  ...state,
  configurations,
});

export const hardware = createReducer(
  {},
  {
    [SET_HARDWARE]: onSetHardware,
    [UPDATE_SELECTED_HARDWARE](state, { selectedItem }) {
      const { checkedStatus, productId } = selectedItem;
      const newSelected = [...state.selected];
      const updateSelected = checkedStatus === 'remove'
        ? newSelected.filter((selectedId) => selectedId !== Math.trunc(productId))
        : newSelected.concat(Math.trunc(productId));
      return { ...state, selected: updateSelected };
    },
    [UPDATE_HARDWARE_CONFIG]: onUpdateConfiguration,
    [UPDATE_HARDWARE_QUANTITY]: onUpdateHardwareQuantity,
    [UPDATE_HARDWARE_COST]: onUpdateHardwareCost,
    [SET_HARDWARE_CONFIGURATIONS]: onSetHardwareConfigurations,
  },
);

export default hardware;
