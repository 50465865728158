import { createSelector } from 'reselect';
import productSkusSelector from './productSkusSelector';
import skuChoicesSelector from './skuChoicesSelector';
import inventoryModes from '../../utils/inventoryModes';
import inventoryModeSelector from './inventoryModeSelector';

export const catalogSkuSelector = createSelector(
  productSkusSelector,
  skuChoicesSelector,
  inventoryModeSelector,
  (productSkus, skuChoices, inventoryMode) => {
    if (productSkus.length === 0) return 0;
    const possibleSkuCatalog = productSkus.filter((sku) => {
      const preferredSku = skuChoices[sku];
      if ((inventoryMode === inventoryModes.SPECIALORDER) && !(preferredSku.isStock)) { return true; }
      return false;
    });
    return possibleSkuCatalog[0];
  },
);

export default catalogSkuSelector;
