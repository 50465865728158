/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { bindActionCreators } from 'redux';
 import HardwareDescription from './HardwareDescription/index';
 import HardwareProducts from './HardwareProducts';
 import * as S from './Styled.HardwareAssembly';
import LoadSpinner from '../../../components/loadspinner';

 import hardwareSubAppLifecycleFactory from '../lifecycle';
 import matchColorToChoices from '../lifecycle/colorMatch';
import rootSelector from '../selectors/rootSelector';
 import edgeTypes from '../edgeType';
import theContext from '../context';
 import callAssemblyLifeCycle from '../utils/callAssemblyLifeCycle';
 import { hardwareAssembliesSelectorByActiveEdge } from '../../../selectors/graphs/hardwareAssemblySelectors';
 import { inventoryModeSelector } from '../../../selectors/catalog';
 import inventoryModes from '../../../utils/inventoryModes';

const setContext = (decksApiUrl, storeId, selectedEdge, selectedColor, callback, boards, deckArea, availableStock) => {
  theContext.selectedEdge = selectedEdge;
  theContext.selectedColor = selectedColor;
  theContext.decksApiUrl = decksApiUrl;
  theContext.storeId = storeId;
  theContext.callback = callback;
  theContext.boards = boards;
  theContext.deckArea = deckArea;
  theContext.availableStock = availableStock;
};

export class HardwareAssembly extends Component {
  componentDidMount() {
    setContext(this.props.decksApiUrl, this.props.storeId, this.props.selectedEdge, this.props.selectedColor, this.props.callback, this.props.boards, this.props.deckArea, this.props.availableStock); // this feels wrong...
    this.props.hardwareLifeCycle(this.props.decksApiUrl, this.props.assemblyId, this.props.storeId, this.props.selectedColor);
  }

  componentDidUpdate(prevProps) {
    setContext(this.props.decksApiUrl, this.props.storeId, this.props.selectedEdge, this.props.selectedColor, this.props.callback, this.props.boards, this.props.deckArea, this.props.availableStock);
    callAssemblyLifeCycle(prevProps, this.props, this.props.hardwareLifeCycle, this.props.colorMatch);
  }

  render() {
    const {
      assemblyId,
      assemblyIsLoading,
      selectedEdge,
      callbackHandler,
      availableStock,
      disable,
      isDeckBoardBOPIS,
      boards,
      deckArea,
    } = this.props;

    // console.log(`availableStock====>: ${JSON.stringify(availableStock)}`);
    return (     
      // eslint-disable-next-line no-alert
      <S.HardwareAssembly id="hardware-assembly" data-assemblyid={assemblyId} disable={isDeckBoardBOPIS}>
        <HardwareDescription selectedEdge={selectedEdge} />
        {
          assemblyIsLoading ?
            <LoadSpinner /> :
            (
              <HardwareProducts
                availableStock={availableStock}
                selectedEdge={selectedEdge}
                callbackHandler={callbackHandler}
                disable={disable}
                boards={boards}
                deckArea={deckArea}
              />
            )
        }
      </S.HardwareAssembly>
    );
  }
}

HardwareAssembly.defaultProps = {
  hardwareLifeCycle: noop,
  colorMatch: noop,
  selectedEdge: edgeTypes.GROOVED,
  callback: noop,
  decksApiUrl: 'apiUrl',
  storeId: '121',
};

HardwareAssembly.propTypes = {
  assemblyId: PropTypes.number.isRequired,
  assemblyIsLoading: PropTypes.bool.isRequired,
  selectedColor: PropTypes.string.isRequired,
  colorMatch: PropTypes.func,
  hardwareLifeCycle: PropTypes.func,
  selectedEdge: PropTypes.string,
  callback: PropTypes.func,
  decksApiUrl: PropTypes.string,
  storeId: PropTypes.number,
  boards: PropTypes.array.isRequired,
  callbackHandler: PropTypes.func.isRequired,
  deckArea: PropTypes.object.isRequired,
  availableStock: PropTypes.number.isRequired,
  disable: PropTypes.bool.isRequired,
  isDeckBoardBOPIS: PropTypes.bool.isRequired,
};


const mapStatetoProps = (state, ownProps) => {
  const root = rootSelector(state);
  const assemblyIsLoading = (Object.entries(root).length === 0 && root.constructor === Object) || ownProps.assemblyId === 0;
  const decksFulfillmentType= inventoryModeSelector(state);
  const hardwareAssemblies = hardwareAssembliesSelectorByActiveEdge(state);

  /*
  **disabling hardware section if decks fulfillment type is BOPIS as there are few limitations with hardware fulfillmnet
    such as in case decks have express delivery,  hardware may or may not have express delivery and that can lead to not
    checking out hardware when express delivery is clicked
  ** disabling if sku can't be determined for hardware which means hardware is not available
  */
  const isDeckBoardBOPIS = decksFulfillmentType === inventoryModes.STOCK ;

  let disable = isDeckBoardBOPIS || hardwareAssemblies.some(h => !h.omsId || h.omsId === "");

  if (!disable) {
    const bopis = hardwareAssemblies.filter(h => h.isBOPIS);
    if (bopis.length > 0) {
      const cantFill =bopis.filter(h => h.quantityAvailable < 1 || h.quantityOrdered > h.quantityAvailable);
      if (cantFill.length > 0) {
        disable = true;
      }
    }  
  }

  return {
    assemblyIsLoading,
    disable,
    isDeckBoardBOPIS,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    hardwareLifeCycle: hardwareSubAppLifecycleFactory,
    colorMatch: matchColorToChoices
  }, dispatch,
);

export default connect(mapStatetoProps, mapDispatchToProps)(HardwareAssembly);
