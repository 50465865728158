import thdCustomer from '@thd-olt-global/thd-customer';

const getCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie && decodedCookie.split(';');

  let found = ca.find((element) => element.startsWith(name) || element.startsWith(` ${name}`));
  if (found && found.length > 0) {
    found = found.trim().substring(name.length, found.length);
    if (found.startsWith('"')) found = found.substring(1);
    if (found.endsWith('"')) found = found.substring(0, found.length - 1);
    return found;
  }
  return '';
};

const cookieExists = (cookieName) => {
  const cookieValue = getCookie(cookieName) || '';
  return cookieValue.length > 0;
};

export const isInternalUser = () => cookieExists('internalUser');

export const getAnalyticsAuthenticationStatus = () => {
  if (isInternalUser()) {
    return 'rep';
  }

  return thdCustomer && thdCustomer.isLoggedIn ? 'consumer' : 'guest';
};

export const getLoggedInUserInfo = () => {
  const cookieValue = getCookie('THD_CUSTOMER') || '';

  if (cookieValue.length === 0) {
    return null;
  }

  return {
    identityId: thdCustomer.svocID,
    email: thdCustomer.email,
  };
};
