import React from 'react';

const WarningIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
    <g fill="none" fillRule="evenodd">
      <path fill="#FFC206" d="M8 0l9 17H0z" />
      <path fill="#FFFFFE" d="M7 5h2.5v3.343l-.302 3.426H7.273L7 8.343V5zm0 11h2.5v-2.538H7V16z" />
    </g>
  </svg>
);

export default WarningIcon;
