import getThdStore from './getThdStore';

export const getSelectedStore = () => {
  const selectedStore = getThdStore();
  selectedStore.storeId = selectedStore.storeNumber;

  // if (window.injectedConfiguratorSettings.isDevEnvironment === true) {
  //   selectedStore = { storeId: '2807', name: 'Minneapolis Ne', phone: '(612)782-9594' };
  // }
  return selectedStore;
};

export default {
  getSelectedStore,
};
