const getColorSelectabilityChanges = (colorChoices) => {
  let changes = null;
  const n = colorChoices.length;
  for (let i = 0; i < n; i++) {
    if (colorChoices[i].isSelected && !colorChoices[i].isSelectable) {
      changes = { from: i };
      break;
    }
  }

  if (!changes) {
    return changes;
  }

  for (let i = changes.from + 1; i < n; i++) {
    if (colorChoices[i].isSelectable) {
      return { ...changes, to: i };
    }
  }

  for (let i = 0; i < n; i++) {
    if (colorChoices[i].isSelectable) {
      return { ...changes, to: i };
    }
  }

  return null;
};

export default getColorSelectabilityChanges;
