import styled from 'styled-components';

export const ModalContainer = styled.div`
    display: flex;
    margin: 30px;
    flex-wrap: wrap;
`;

export const ModalContentContainer = styled.div`
    display: flex;
    flex-wrap: wrap;

    @media (min-width: 768px) {
        flex-wrap: nowrap;
    }
`;

export const ModalContent = styled.div`
    flex: 1;
    order: 2;

    @media (min-width: 768px) {
        order: 1;
        flex: 1 1 50%;
        padding-right: 30px;
    }

    p {
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 20px;
    }
    h2 {
        flex: 0 0 100%;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 30px;
    }
`;

export const ModalMedia = styled.div`
    flex: 1 1 100%;
    order: 1;
    padding-bottom: 26px;

    @media (min-width: 768px) {
        flex: 1 1 auto;
        order: 2;
        padding-bottom: 0;
    }
`;

export const ModalMediaVideo = styled.div`
    flex: 1 1 100%;
    padding-bottom: 26px;

    @media (min-width: 768px) {
        flex: 1 1 50%;
        padding-bottom: 0;
        padding-right: 26px;
    }

    .video-js {
        position: relative;

        .vjs-tech {
            position: relative;
        }
    }
`;
