import values from 'lodash/values';
import keys from 'lodash/keys';
import { getKitOMSIDs } from './getKitOMSIDs';
import { makeCartableKit, getFulfillmentInfo } from './makeCartableKit';
import makeKitForAnalyticsFromcartableKit from './makeKitForAnalyticsFromcartableKit';
import { sendKitToAnalytics } from './sendKitToAnalytics';
import failedToAddKitToCart from './failedToAddKitToCart';
import saveBoardConfiguration from './saveBoardConfiguration';
import saveItemConfiguration from './saveItemConfiguration';
import { assert } from '../../../apps/debug/assert';
import { cartProductSelector } from '../../../selectors';
import store from '../../../store/globalStore';

let cartFulfillment;
const localizer = typeof window !== 'undefined' ? window?.THD_LOCALIZER_AUTO_INIT?.Localizer : null;
const storeInfo = localizer?.getLocalizedStore();
function isSaveConfigurationsSuccessful(results, state) {
  if (!results || results.configurationGuid.length <= 0) {
    failedToAddKitToCart('Configuration could not be created for one or more products.', state);
    return false;
  }
  return true;
}

function hydrateKitWithConfigurationforBoard(kitboard, savedboardConfig) {
  /* eslint no-param-reassign: ["error", { "props": false }] */
  kitboard.board.mVendor = savedboardConfig.mVendor;
  kitboard.board.configurationGuid = savedboardConfig.configurationGuid;
}

function hydrateHardwareItemsWithConfiguration(hardware, hardwareConfigurations) {
  keys(hardware).forEach((key) => {
    const config = hardwareConfigurations.find((c) => c.productId === parseInt(key, 10));
    if (config) {
      hardware[key].mVendor = config.mVendor;
      hardware[key].configurationGuid = config.configurationGuid;
    }
  });
}
function getProductsFrom(kit) {
  const hardware = Object.keys(kit.hardware).map((id) => ({
    productId: id,
    omsId: kit.hardware[id].skuType === 'HomeDepot_OMSID' ? kit.hardware[id].sku : undefined,
    mVendor: kit.hardware[id].mVendor,
  }));

  const products = [
    {
      productId: kit.board.productId,
      mVendor: kit.board.mVendor,
    },
    ...hardware,
  ];
  return products;
}
function getCartableKit(productOmsIds, kit) {

  const boardItem = {
    omsId: productOmsIds[kit.board.productId],
    configurationId: kit.board.configurationGuid,
    quantity: kit.board.quantity,
    unitPrice: kit.board.unitPrice,
  };
  const thdHardwareItems = Object.keys(kit.hardware)
    .filter((key) => kit.hardware[key].skuType === 'HomeDepot_OMSID')
    .map((productId) => ({
      omsId: productOmsIds[productId],
      quantity: kit.hardware[productId].quantity,
      unitPrice: kit.hardware[productId].unitPrice,
      isStock: kit.hardware[productId].isStock,
      quantityAvailable: kit.hardware[productId].quantityAvailable,
      productId: kit.hardware[productId].productId,
      isBOPIS: kit.hardware[productId].isBOPIS,
    }));

  const qcHardwareItems = Object.keys(kit.hardware)
    .filter((key) => kit.hardware[key].skuType === 'QuoteCenter')
    .map((productId) => ({
      omsId: productOmsIds[productId],
      configurationId: kit.hardware[productId].configurationGuid,
      quantity: kit.hardware[productId].quantity,
      unitPrice: kit.hardware[productId].unitPrice,
    }));

  const items = [boardItem, ...thdHardwareItems, ...qcHardwareItems];
  thdHardwareItems.forEach((hw) => assert({ condition: typeof hw.isBOPIS === 'boolean', error: 'No BOPIS Flag', data: { hw } }));
  const fulfillmentInfo = getFulfillmentInfo(kit.thdStoreId, items);
  const cartableKit = makeCartableKit(items, fulfillmentInfo);
  cartFulfillment = fulfillmentInfo;

  return cartableKit;
}

async function createHardwareConfigurations(context, storeId, hardwareProducts) {
  const configurationResponses = [];
  const saveconfigurationPromises = hardwareProducts.map(async (product) => {
    try {
      const configurationResponse = await saveItemConfiguration(context.apis,
        context.siteId,
        storeId,
        product,
        context.hiddenChoices,);
      configurationResponses.push(configurationResponse);
    } catch (error) {
      console.log(error);
    }
  });

  await Promise.all(saveconfigurationPromises);

  return configurationResponses;
}
function isQuoteCenterHardware(hardwareItems) {
  return hardwareItems.some((item) => item.skuType === 'QuoteCenter');
}

export default function kitCartOrchestrator(context, state, cartOptions) {
  async function cartTheKit(kit) {
    const boardConfigurations = await saveBoardConfiguration(
      context.apis,
      context.siteId,
      kit.thdStoreId,
      kit.board,
      context.hiddenChoices,
    );
    if (!isSaveConfigurationsSuccessful(boardConfigurations, state)) return [];

    hydrateKitWithConfigurationforBoard(kit, boardConfigurations);

    if (kit.hardware && isQuoteCenterHardware(values(kit.hardware))) {
      const qcHardwareItems = values(kit.hardware).filter((item) => item.skuType === 'QuoteCenter');
      const hardwareConfigurations = await createHardwareConfigurations(context, kit.thdStoreId, qcHardwareItems);
      if (hardwareConfigurations.filter((config) => config === null).length > 0) {
        return [];
      }
      hydrateHardwareItemsWithConfiguration(kit.hardware, hardwareConfigurations);
    }

    const products = getProductsFrom(kit);
    const productOmsIds = await getKitOMSIDs(context.apis.omsApiUrl, products);
    const allProductsMapToTheirVendors = Object.keys(productOmsIds).length === products.length;

    if (!allProductsMapToTheirVendors) {
      failedToAddKitToCart('One or more products in the kit did not map to their vendors.', state);
      return [];
    }

    const cartableKit = getCartableKit(
      productOmsIds,
      kit,
    );
    const kitForAnalytics = makeKitForAnalyticsFromcartableKit(cartableKit.CartRequest.itemDetails);
    await sendKitToAnalytics(kitForAnalytics);

    const items = [
      {
        isAnchor: true,
        isBase: true,
        itemId: cartableKit.CartRequest.itemDetails[0].itemId.toString(),
      }
    ];
    // eslint-disable-next-line no-unused-expressions, no-return-assign
    cartableKit?.CartRequest?.itemDetails.forEach((item) => item.itemId = item.itemId.toString());
    const product = cartProductSelector(store.getState());

    if (cartOptions?.triggeredFromStoreDrawer) {
      window.postMessage({
        action: 'event',
        type: 'cart-pss-atc-event',
        cartModel: cartableKit?.CartRequest?.itemDetails,
        cartOptions: {
          host: window?.location?.origin || 'https://www.homedepot.com',
          channel: cartOptions?.channel || 'desktop',
          skipMisship: true,
          fulfillmentMethod: cartFulfillment[0]?.method,
          storeId: kit.thdStoreId,
          storeZip: storeInfo?.zipcode,
          zipCode: storeInfo?.zipcode,
        }
      });
    } else {
      LIFE_CYCLE_EVENT_BUS.trigger('cart.add-to-cart', {
        cartReqParams: cartableKit?.CartRequest?.itemDetails,
        cartOptions: {
          bogoInfo: undefined,
          channel: 'desktop',
          fulfillmentMethod: cartFulfillment[0]?.method,
          storeId: kit.thdStoreId,
          storeZip: storeInfo?.zipcode,
          zipCode: storeInfo?.zipcode,
        },
      });
    }

    return cartableKit?.CartRequest?.itemDetails;
  }
  return cartTheKit;
}
