/* istanbul ignore file */
import DefaultTemplate from './DefaultTemplate';
import ColorTemplate from './ColorTemplate';
import Dropdown from './Dropdown';

const deckingTemplates = {
  Color: ColorTemplate,
  Dropdown,
  Hidden: Dropdown,
  Default: DefaultTemplate,
};

export default deckingTemplates;
