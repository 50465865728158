import Api from '../../../services/axios';

export const quoteCenterOffering = (decksApiUrl, storeId, configurationId) => {
  const url = `${decksApiUrl}/Composite/GetQCOffering?storeId=${storeId}&qcProductId=${configurationId}`;
  const api = Api();
  Object.assign(api.defaults, { crossDomain: true });
  return api.get(url);
};

export default quoteCenterOffering;
