import React from 'react';
import PropTypes from 'prop-types';
import * as S from './Styled.HardwareDescription';
import HardwareDescriptionContent from '../HardwareDescriptionContent/index';
import DeckInfoLink from '../../../../apps/templates/deckInfoLink/index';

const HardwareDescription = ({ selectedEdge }) => (
  <S.HardwareDescription>
    <S.HardwareDescriptionHeader>
      Installation Hardware
      <sup>
        <DeckInfoLink useIcon title="Installation Hardware" term="tooltip/installation-hardware" />
      </sup>
    </S.HardwareDescriptionHeader>
    <HardwareDescriptionContent type={selectedEdge} />
  </S.HardwareDescription>
);

HardwareDescription.propTypes = {
  selectedEdge: PropTypes.string.isRequired,
};

export default HardwareDescription;
