import axios from 'axios';
import { serializeToQueryString } from '../utils/serializeToSkuMappingQueryString';

export const getHardwarePrice = (payload) => axios
  .get(`${
    window.injectedConfiguratorSettings.customBaseUrl
  }/skumapping-api/api/v3/MapSkuAndGetPrice?${serializeToQueryString(payload)}`)
  .then((response) => response.data);

export default getHardwarePrice;
