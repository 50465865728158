function URL(str) {
  return /^https?:\/\//.test(str);
}

function OptionalUrl(props, propName, componentName) {
  if (props[propName] && !URL(props[propName])) {
    return new Error(`${propName} should be a url in ${componentName}:  Value found was ${props[propName]}`);
  }
}

export { URL, OptionalUrl };