import { createSelector } from 'reselect';
import productSkusSelector from './productSkusSelector';
import skuChoicesSelector from './skuChoicesSelector';
import inventoryModes from '../../utils/inventoryModes';
import marketAvailabilitySelector from '../marketAvailabilitySelector';

export const inventoryModeSelector = createSelector(
  productSkusSelector,
  skuChoicesSelector,
  marketAvailabilitySelector,
  (productSkus, skuChoices, isInMarket) => {
    if (!isInMarket) return inventoryModes.STOCK;

    const possibleCatalogEntries = productSkus.map((sku) => skuChoices[sku]);

    const anyStock = possibleCatalogEntries.reduce(
      (agg, entry) => agg || entry.isStock,
      false,
    );

    const choiceCombo = possibleCatalogEntries.reduce(
      (agg, entry) => agg || entry.skuChoices.join(),
      '',
    );

    const soOnlySkus = Object.keys(skuChoices).filter((sku) => skuChoices[sku].isStock === false);

    const soOnlyChoiceCombos = soOnlySkus.map((sku) => skuChoices[sku].skuChoices.join());

    if (anyStock) {
      const stockSkuData = possibleCatalogEntries.find((x) => x.isStock);
      const soItemFound = soOnlyChoiceCombos.find((combo) => combo === choiceCombo);

      if (stockSkuData.quantityAvailable === 0) {
        return soItemFound ? inventoryModes.SPECIALORDER : inventoryModes.STOCK;
      }

      return inventoryModes.STOCK;
    }

    return inventoryModes.SPECIALORDER;
  },
);

export default inventoryModeSelector;
