import { createSelector } from 'reselect';

import skuChoicesSelector from './skuChoicesSelector';
import inventoryMode from './inventoryModeSelector';
import productSkusSelector from './productSkusSelector';
import inventoryModes from '../../utils/inventoryModes';

const modeBasedFinders = {
  [inventoryModes.STOCK]: (sku) => sku.isStock,
  [inventoryModes.SPECIALORDER]: (sku) => !sku.isStock,
};

export const selectedProductSkuDataSelector = createSelector(
  productSkusSelector,
  skuChoicesSelector,
  inventoryMode,
  (productSkus, skuChoices, inventoryType) => {
    if (productSkus.length === 0) return {};

    const possibleSkuCatalogs = productSkus.map((sku) => ({ sku, ...skuChoices[sku] }));

    if (possibleSkuCatalogs.length === 1) return possibleSkuCatalogs[0];

    const theCatalog = possibleSkuCatalogs.find(modeBasedFinders[inventoryType]);
    return theCatalog;
  },
);

export default selectedProductSkuDataSelector;
