import { createAction } from './createAction';

export const GET_HARDWARE = 'GET_HARDWARE';

export const getHardware = createAction(
  GET_HARDWARE,
  'baseUrl',
  'assemblyId',
);

export const SET_HARDWARE = 'SET_HARDWARE';

export const setHardware = createAction(SET_HARDWARE, 'hardwareList');

export const UPDATE_SELECTED_HARDWARE = 'UPDATE_SELECTED_HARDWARE';

export const updateSelectedHardware = createAction(
  UPDATE_SELECTED_HARDWARE,
  'selectedItem',
);

export const UPDATE_HARDWARE_CONFIG = 'UPDATE_HARDWARE_CONFIG';

export const updateHardwareConfiguration = createAction(
  UPDATE_HARDWARE_CONFIG,
  'hardwareId',
  'optionId',
  'choiceId',
  'choiceValue',
);

export const UPDATE_HARDWARE_QUANTITY = 'UPDATE_HARDWARE_QUANTITY';

export const updateHardwareQuantity = createAction(
  UPDATE_HARDWARE_QUANTITY,
  'hardwareId',
  'newQuantity',
);

export const UPDATE_HARDWARE_COST = 'UPDATE_HARDWARE_COST';

export const updateHardwareCost = createAction(
  UPDATE_HARDWARE_COST,
  'hardwareId',
  'newCost',
  'vendorNumber',
  'unitPrice',
  'externalId',
  'storeId',
  'vendorPartNumber',
);

export const SET_HARDWARE_CONFIGURATIONS = 'SET_HARDWARE_CONFIGURATIONS';
export const setHardwareConfigurations = createAction(SET_HARDWARE_CONFIGURATIONS, 'configurations');
