import React from 'react';

const ErrorBox = () => (
  <svg width="17px" height="17px" viewBox="0 0 17 17">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Deck---Item-Not-Available-6" transform="translate(-783.000000, -811.000000)">
        <rect fill="#FFFFFF" x="0" y="0" />
        <g transform="translate(28.000000, 143.000000)">
          <g transform="translate(447.000000, 472.000000)" />
        </g>
        <g transform="translate(783.000000, 810.000000)">
          <g transform="translate(0.000000, 1.000000)">
            <g>
              <rect id="Rectangle-172" fill="#ED1C24" x="0" y="0" width="17" height="17" />
              <path d="M7,3 L9.5,3 L9.5,6.34325851 L9.19829754,9.76923077 L7.27314848,9.76923077 L7,6.34325851 L7,3 Z M7,14 L9.5,14 L9.5,11.4615385 L7,11.4615385 L7,14 Z" fill="#FFFFFE" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ErrorBox;
