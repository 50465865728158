import { createReducer } from '../utils';
import { SET_ROOT_ASSEMBLY } from '../actions';

const onSetAssembly = (state, { assembly }) => ({ ...assembly });

export const assembly = createReducer(
  {},
  {
    [SET_ROOT_ASSEMBLY]: onSetAssembly,
  },
);

export default assembly;
