import { getProductOmsId } from '../../../services';

export async function getKitOMSIDs(omsApiUrl, products) {
  const omsIdsToProductIds = products.filter((p) => p.omsId).reduce((dict, p) => {
    // eslint-disable-next-line no-param-reassign
    dict[p.productId] = p.omsId;
    return dict;
  }, {});

  const productsWithoutOmsIds = products.filter((hw) => !hw.omsId);
  const omsIdRetrieverPromises = productsWithoutOmsIds.map(async (product) => {
    try {
      const response = await getProductOmsId(omsApiUrl, product.productId, product.mVendor);
      omsIdsToProductIds[product.productId] = response.data.OmsId;
    } catch (error) {
      // console.log(`Error Getting OMSID for ProductID : ${product.productId},\n           MultivendorID:${product.mVendor} Error:${JSON.stringify(error)}`);
    }
  });

  await Promise.all(omsIdRetrieverPromises);

  return omsIdsToProductIds;
}

export default getKitOMSIDs;
