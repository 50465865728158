import { createAction } from './createAction';

export const PRICE_CHANGED_SUCCESS = 'GCC_CONFIGURATOR/PRICE_CHANGED_SUCCESS';

export const priceChangedSuccess = createAction(PRICE_CHANGED_SUCCESS, 'choiceDeltas', 'asConfigured', 'strikeThroughPriceModel');

export const CHOICE_CHANGED_SUCCESS = 'GCC_CONFIGURATOR/CHOICE_CHANGED_SUCCESS';

export const choiceChangedSuccess = createAction(CHOICE_CHANGED_SUCCESS, 'choiceId', 'choiceValue', 'siblingIds', 'settingDefaultsOnChange');

export const RE_CALCULATE_PRICE = 'RE_CALCULATE_PRICE';
export const recalculatePrice = createAction(RE_CALCULATE_PRICE);
