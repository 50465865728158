import { call, takeLatest } from 'redux-saga/effects';
import { safeSaga } from '../utils';
import { handlePrerendering } from './handlePrerendering';
import { GET_MEDIA_GALLERY_IMAGES_SUCCESS } from '../../actions/mediaGalleryActions';

function* prerender() {
  yield call(handlePrerendering);
}

export function* prerenderSaga() {
  // console.log('Prerender saga started');

  yield takeLatest(GET_MEDIA_GALLERY_IMAGES_SUCCESS, safeSaga(prerender));
}

export default prerenderSaga;
