import { call, put, select } from 'redux-saga/effects';

import { updateHardwareCost } from '../../actions';
import { getHardwarePrice } from '../../services';
import hiddenOptionIdsSelector from '../../selectors/hardware/hiddenOptionIdsSelector';

function* computeHardwareCost(storeId, hardwareId, configuration, estimate) {
  if (!estimate || !estimate.quantity) {
    console.log(`Hardware Price Saga Error: Missing Quantity for ${JSON.stringify(hardwareId)}`);
    return;
  }

  if (!configuration) {
    console.log(`Hardware Price Saga Error: Missing Configuration for ${JSON.stringify(hardwareId)}`);
    return;
  }

  const optionIds = yield select(hiddenOptionIdsSelector);
  const hiddenOptions = Object.keys(optionIds).map((k) => `${optionIds[k]}`);
  const payload = {
    ProductId: hardwareId,
    Siteid: 11,
    StoreId: storeId,
    choices: Object.keys(configuration)
      .filter((k) => hiddenOptions.indexOf(k) === -1)
      .map((k) => {
        const { choiceId, choiceValue } = configuration[k];
        return {
          OptionId: k,
          ChoiceId: choiceId,
          ChoiceValue: choiceValue,
          OptionType: '',
          Tags: [],
        };
      }),
  };
  const {
    asConfigured,
    vendorNumber,
    qcProductId,
    vendorPartNumber,
  } = yield call(getHardwarePrice, payload);

  if (!asConfigured) {
    console.log(`Hardware Price Saga Error: No Unit Price for ${JSON.stringify(hardwareId)}.`);
  }

  const fullPrice = asConfigured * estimate.quantity;
  yield put(updateHardwareCost(
    hardwareId,
    fullPrice || 0,
    vendorNumber,
    asConfigured || 0,
    qcProductId,
    storeId,
    vendorPartNumber,
  ));
}

export default computeHardwareCost;
