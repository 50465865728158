import styled from 'styled-components';

export const Row = styled.div`
display: flex;
flex-direction: row;
`;

export const Column = styled.div`
display: flex;
flex-direction: column;
`;

export const GrowthGroup = styled.div`
flex-grow: 1;
`;

export const HorizontalBar = styled.div`
  height: 2px;
  width: 100%;
  margin-top: 13px;
  margin-bottom: 13px;
  background-color: #979797;
`;
