/* eslint-disable import/prefer-default-export */
/* eslint-disable-next-line  */
export const createAction = (type, ...argNames) => (...args) => {
  /* eslint-disable-next-line  */
    const action = { type };
  argNames.forEach((arg, index) => {
    action[argNames[index]] = args[index];
  });
  // console.log('action creator=>', action);
  return action;
};
