import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ErrorMessage from './ErrorMessage';
import colorAvailabilityEvalutorFactory from '../../businessLogic/choiceAvailability/colorAvailabilityEvalutorFactory';
import marketAvailabilitySelector from '../../selectors/marketAvailabilitySelector';
import updateAfterSelection from '../actions/updateAfterSelection';
import getColorSelectabilityChanges from '../../utils/getColorSelectabilityChanges';

class ColorTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }

  componentDidUpdate() {
    const availableChoices = this.props.choices.filter(choice => this.props.isColorChoiceAvailable(choice.id));

     const selectedChoice = this.props.choices.find(c => c.isSelected);
     const selectedChoiceUnavailable = selectedChoice && !availableChoices.some(c => c.id === selectedChoice.id);
    if (availableChoices.length > 0 && selectedChoiceUnavailable) {
      this.changeColor(availableChoices[0]);
    }
  }

  changeSelection(choice) {
    this.colorChange(choice.id, choice.shortLabel, this.props.isColorChoiceAvailable(choice.id));
  }

  getSelectedPrimaryColor() {
    return this.props.choices.find((choice) => choice.isSelected);
  }

  colorChange(e, choiceId, choiceValue, isAvailable) {
    if (!isAvailable) return;
    this.setState({
      active: false,
    });

    const currentChoice = this.props.choices.find((choice) => choice.isSelected);
    if (
      (currentChoice && currentChoice.id === choiceId)
      || !this.state.active
    ) {
      return;
    }

    this.props
      .onChoiceSelected(this.props.option.id, choiceId, choiceValue)
      .then(() => this.props.onChoiceSelectedSuccess());
  }

  toggle(e) {
    e.stopPropagation();
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ active: !this.state.active });
  }

  blur(e) {
    e.stopPropagation();
    this.setState({ active: false });
  }

  close(e) {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      e.stopPropagation();
      this.setState({ active: false });
    }
  }

  render() {
    const selectedChoice = this.getSelectedPrimaryColor();
    const active = this.state.active ? 'active' : '';
    return (
      <div
        className={`sku_variant product_sku_Overlay_ListBoxes_1 pip-drop-down__header ${active}`}
        role="presentation"
        tabIndex="-1"
      >
        <div className="attribute_label">Color/Finish</div>
        {!selectedChoice && !this.props.disableOption && (
          <ErrorMessage message="Please select from available choices" />
        )}
        <a
          className="customLabel drop-down__title"
          onClick={(e) => this.toggle(e)}
          role="presentation"
        >
          {selectedChoice && <img alt="" src={`${selectedChoice?.mediaUrl}?fmt=jpeg&fit=constrain,1&hei=100`} />}
          <span id="selectlistOption1" className="custom select">
            {selectedChoice && selectedChoice.value}
            {!selectedChoice && 'Select a Color'}
          </span>
        </a>
        <div
          className={
            window.prerender
              ? 'drop-down__content-wrapper--open'
              : 'drop-down__content-wrapper'
          }
        >
          <ul className="listOptions drop-down__list" id="labellistOption1">
            {this.props.choices.map((choice) => (
              <li
                key={choice.id}
                onClick={(e) => this.colorChange(
                  e,
                  choice.id,
                  choice.shortLabel,
                  this.props.isColorChoiceAvailable(choice.id),
                )}
                role="presentation"
              >
                <span className="drop-down__hover-effect">
                  <a
                    className={
                      this.props.isColorChoiceAvailable(choice.id)
                        ? 'enabled'
                        : 'disabled'
                    }
                  >
                    <img
                      alt={choice.longLabel}
                      title={choice.longLabel}
                      src={`${choice.mediaUrl}?fmt=jpeg&fit=constrain,1&hei=100`}
                    />
                    {' '}
                    {choice.value}
                  </a>
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

ColorTemplate.propTypes = {
  choices: PropTypes.array.isRequired,
  option: PropTypes.object.isRequired,
  onChoiceSelected: PropTypes.func.isRequired,
  onChoiceSelectedSuccess: PropTypes.func.isRequired,
  disableOption: PropTypes.bool.isRequired,
  isColorChoiceAvailable: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { option }) => {
  const { configurator } = state;
  const isColorChoiceAvailable = colorAvailabilityEvalutorFactory(state);
  const sortedChoices = option.choices
    .map((it) => configurator.choices[it])
    .sort((a, b) => a.sequence - b.sequence)

  // eslint-disable-next-line no-param-reassign
  sortedChoices.forEach(choice => { choice.isSelectable = isColorChoiceAvailable(choice.id) })

  const changes = getColorSelectabilityChanges(sortedChoices);
  if (changes) {
    sortedChoices[changes.from].isSelected = false;
    sortedChoices[changes.to].isSelected = true;
  }

  return {
    choices: sortedChoices,
    disableOption: !marketAvailabilitySelector(state),
    isColorChoiceAvailable,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChoiceSelectedSuccess: () => {
    dispatch(updateAfterSelection());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ColorTemplate);
