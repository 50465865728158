import {
  setPageInfo,
  setAdditionalPageInfo,
  setAuthenticationStatus,
  getAnalyticsAuthenticationStatus,
} from '../common';

import { sendAddToCartEvent, sendSampleAddToCartEvent } from '.';

const setTheHomeDepotCategoryObject = (omsId, productName, brand, logEventsToConsole) => {
  const categoryData = {
    primaryCategory: 'Lumber & Composites',
    subCategory1: 'Lumber & Composites>Decking',
    subCategory2: 'Lumber & Composites>Decking>Deck Boards>Composite Decking Boards',
  };

  setAdditionalPageInfo('category', categoryData);

  const pageInfoData = {
    pageLoadEvent: 'product view',
    pageName: `productdetails>${omsId}`,
    pageType: 'pip',
  };

  setAdditionalPageInfo('pageInfo', pageInfoData);

  const itemData = {
    productInfo: {
      sku: `${omsId}`,
      productName,
      manufacturer: `${brand}`,
      productTYpe: 'mechandise',
    },
  };

  setAdditionalPageInfo('item', itemData);
  if (logEventsToConsole) {
    console.log('window.digitalData', JSON.stringify(window.digitalData));
  }
};

export const customProductPipLoaded = ({
  omsId, productName, brand, logEventsToConsole,
}) => {
  setPageInfo('product view', 'pip');
  setTheHomeDepotCategoryObject(omsId, productName, brand, logEventsToConsole);
  setAuthenticationStatus(getAnalyticsAuthenticationStatus());
};

export async function addToCartAnalytics(items, fulfillmentMethod, logEventsToConsole) {
  await sendAddToCartEvent(items, fulfillmentMethod, logEventsToConsole);
}

export const sampleAddToCartAnalytics = (items, logEventsToConsole) => {
  sendSampleAddToCartEvent(items, logEventsToConsole);
};
