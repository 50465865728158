import { createSelector } from 'reselect';
import selectedProductSkuDataSelector from './selectedProductSkuDataSelector';
import marketAvailabilitySelector from '../marketAvailabilitySelector';

export const availableStockSelector = createSelector(
  selectedProductSkuDataSelector,
  marketAvailabilitySelector,
  (skuData, isInMarket) => (isInMarket ? skuData.quantityAvailable || 0 : 0),
);

export default availableStockSelector;
