export default (state) => {
  const { hardwareProducts, allIds } = state;
  const exampleHardware = hardwareProducts[allIds[0]];
  const vendorOption = exampleHardware.options.find((o) => o.internalName === 'External_VendorId');
  const storeOption = exampleHardware.options.find((o) => o.internalName === 'Decking_ExternalStoreId');
  const productOption = exampleHardware.options.find((o) => o.internalName === 'Decking_ExternalProductId');
  const partNumberOption = exampleHardware.options.find((o) => o.internalName === 'External_VendorPartNumber');

  const foundAllOptions = vendorOption && storeOption && productOption && partNumberOption;
  if (foundAllOptions) {
    return {
      vendorOptionId: vendorOption.id,
      storeOptionId: storeOption.id,
      productOptionId: productOption.id,
      partNumberOptionId: partNumberOption.id,
    };
  }

  return {
    vendorOptionId: 0,
    storeOptionId: 0,
    productOptionId: 0,
  };
};
