/* eslint-disable */
import Api from './axios';
import { assertHasShape, assert } from '../apps/debug/assert';


export const getAssembly = (decksApiUrl, assemblyId) => {
  const url = `${decksApiUrl}/GetProductAssembly?assemblyId=${assemblyId}`;
  const api = Api();
  return api.get(url).then(response => {
    const assembly = response.data.productAssembly;
    assert({
      condition: assembly !== undefined,
      error: 'Product Assembly is Undefined'
    });
    if (assembly) {
      assertHasShape('getAssembly response', assembly, {
        'assemblyId': 'number',
        'displayName': 'string',
        'assemblyType': 'string',
        'products': 'array',
        'assemblies': 'populatedArray'
      });
    }
    return response;
  });

}

export default getAssembly;
