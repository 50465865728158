import Api from './axios';

export const getSkuCatalog = (decksApiUrl, storeId, productId, externalId) => {
  const url = `${decksApiUrl}/GetAllAvailableSkusAndPrices`;
  const params = `?storeId=${storeId}&productId=${productId}&externalId=${externalId}`;
  const api = Api();
  return api.get(url + params);
};

export default { getSkuCatalog };
