import { call, put, takeLatest } from 'redux-saga/effects';

import { getAssembly } from '../../services/assemblyApi';

import { GET_HARDWARE, setRootAssembly } from '../../actions';
import { safeSaga } from '../utils';

function* getHardware(action) {
  const { baseUrl, assemblyId } = action;
  let theAssembly = {};
  if (assemblyId) {
    const { data } = yield call(
      getAssembly,
      baseUrl,
      assemblyId,
    );
    theAssembly = data.productAssembly;
  }
  yield put(setRootAssembly(theAssembly));
}

export function* hardwareSaga() {
  // console.log('Hardware Products saga started');
  yield takeLatest(GET_HARDWARE, safeSaga(getHardware));
}

export default hardwareSaga;
