import { createAction } from './createAction';

export const UPDATE_SUB_APP_HARDWARE_PRICING = 'UPDATE_SUB_APP_HARDWARE_PRICING';

export const updateSubAppHardwarePricing = createAction(
  UPDATE_SUB_APP_HARDWARE_PRICING, 'subappId', 'productId', 'pricing'
);

export const UPDATE_SUB_APP_HARDWARE_QUANTITY = 'UPDATE_SUB_APP_HARDWARE_QUANTITY';

export const updateSubAppHardwareQuantity = createAction(
  UPDATE_SUB_APP_HARDWARE_QUANTITY, 'subappId', 'productId', 'quantity'
);

export const SUB_APP_HARDWARE_SELECTED = 'SUB_APP_HARDWARE_SELECTED';

export const subAppHardwareSelected = createAction(
  SUB_APP_HARDWARE_SELECTED, 'subappId', 'productId', 'selected'
);

export const SUB_APP_SELECTED_EDGE_CHANGED = 'SUB_APP_SELECTED_EDGE_CHANGED';

export const subAppSelectedEdgeChanged = createAction(SUB_APP_SELECTED_EDGE_CHANGED);
