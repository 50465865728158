import { createSelector } from 'reselect';
import memoize from 'lodash/memoize';

import assemblySelector from './assemblySelector';

export const childAssemblyByTypeSelectorFactory = (desiredAssemblyType) => createSelector(assemblySelector,
  (assembly) => {
    const childAssemblies = assembly.assemblies || [];
    const assemblyOfType = childAssemblies.find((a) => a.assemblyType === desiredAssemblyType);
    return (assemblyOfType) || {};
  });

export default memoize(childAssemblyByTypeSelectorFactory);
