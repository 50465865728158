import styled from 'styled-components';

export const Label = styled.label`
  height: 100%;
  padding-left: 32px;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  user-select: none;
  display: flex;

  &:before, &:after {
    position: absolute;
    content: "";
    margin-left: 0;
  }

  &:before {
    width: 10px;
    height: 5px;
    top: 4px;
    left: 4px;
    border: 3px solid #fff;
    border-top: none;
    border-right: none;
    background: transparent;
    transform: rotate(-45deg);
    opacity: 0;
    z-index: 1;
  }

  &:after {
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    border: 2px solid #999;
    background-color: #${(props) => (props.inactive ? 'eee' : 'fff')};
    background-clip: padding-box;
    cursor: pointer;
    box-sizing: border-box;
  }
`;

export const Checkbox = styled.input`
  position: absolute;

  &:checked + Label:before {
    opacity: 1;
  }

   &:checked + Label:after {
    border: 0;
    background-color: #f96302;
  }
`;

export const ImageContainer = styled.div`
  flex: 0 1 60px;
  min-width: 60px;
  max-width: 60px;
  margin: 0 5px 0 9px;
  position: relative;

  img {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const Content = styled.div`
  border-left: 1px solid #cccccc;
  padding-left: 12px;
  flex: 1 1 auto;
  text-align: left;
  margin-left: 12px;
  line-height: 16px;
`;

export const ShortLabel = styled.h5`
  font-weight: bold;
  font-size: 14px;
`;

export const Price = styled.span`
  font-weight: bold;
  font-size: 14px;
`;

export const Name = styled.p`
  font-size: 14px;
`;

export const LongLabel = styled.p`
  font-size: 12px;
`;
