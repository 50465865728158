/* eslint-disable */

const assertionPrefix = 'cfg';

function assert(params) {
  if (!params.condition) {
    console.error(`${assertionPrefix}:assert: ${params.error}\n${JSON.stringify({ data: params.data }, null, 4)}`);
    if (params.debugOnError) {
      debugger;
    }
  }
}

// eslint-disable-next-line lodash/prefer-is-nil
const isValid = (obj) => obj !== undefined && obj !== null;

function hasShape(actual, expected) {
  const validations = {
    populatedObject: (x) => Object.keys(x).length > 0,
    emptyObject: (x) => x === {},
    populatedArray: (x) => x.length > 0,
    emptyArray: (x) => x.length === 0,
    string: (x) => typeof x === 'string',
    number: (x) => typeof x === 'number',
    null: (x) => x === null,
    undefined: (x) => x === undefined,
    array: (x) => Array.isArray(x),
    boolean: (x) => typeof x === 'boolean',
    nullableString: (x) => x === null || (typeof x === 'string'),
    positiveNumber: (x) => typeof x === 'number' && x > 0,
    nonNegativeNumber: (x) => typeof x === 'number' && x >= 0,
  };
  const fields = Object.keys(expected);
  for (let i = 0; i < fields.length; i++) {
    const field = fields[i];
    const actualValue = actual[field];

    const expectedFn = (typeof expected[field] === 'function')
      ? expected[field]
      : validations[expected[field]];
    if (actualValue === undefined || !expectedFn(actualValue)) {
      return false;
    }
  }
  return true;
}

function assertHasShape(title, actual, expected, debugOnError) {
  assert({
    condition: isValid(actual),
    error: `${title} empty`,
    debugOnError
  });
  assert({
    condition: typeof actual === 'object',
    error: `${title} not an object`,
    debugOnError
  });
  const comparisonObj = { expected, actual };
  const errorDump = JSON.stringify(comparisonObj, null, 4);
  assert({
    condition: hasShape(actual, expected),
    error: `${title} has wrong shape: ${errorDump}`,
    debugOnError
  });
}

function assertAssemblyShape(assembly) {
  assertHasShape('Hardware Assembly', assembly, {
    productId: 'string',
    partnerProductId: 'string',
    name: 'string',
    shortLabel: 'string',
    edge: 'string',
    quantityAvailable: 'nonNegativeNumber',
    price: 'number',
    isBOPIS: 'boolean',

    // TODO: There are lifecycle problems that mean we cannot assert on these two
    // fields, as this may be called on bad data.  The calls repeatedly happen until
    // good data comes in.  This needs to be resolved, but time forbids a proper
    // fix of the issue, so I'm leaving out these checks.
    // quantityOrdered: 'positiveNumber',
  });
}

export {
  assert,
  isValid,
  hasShape,
  assertHasShape,
  assertionPrefix,
  assertAssemblyShape
};
