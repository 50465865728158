export function serializeToQueryString(obj) {
  const { choices } = obj;

  const choicesQueryString = `${Object.keys(choices).reduce((a, k) => {
    a.push(`Choices[]=${encodeURIComponent(JSON.stringify(choices[k]))}`); return a;
  }, []).join('&')}`;

  return `ProductId=${obj.ProductId}&StoreId=${obj.StoreId}&${choicesQueryString}`;
}

export default serializeToQueryString;
