import Api from './axios';
import { assertHasShape } from '../apps/debug/assert';

export const getVisualizerImages = (decksApiUrl, request) => {
  const api = Api();
  assertHasShape('Request', request, {
    Sku: 'string',
    ProductId: 'number',
    Scene7Folder: 'string'
  });
  const { Sku, ProductId, Scene7Folder } = request;
  const hasSku = Sku ? `Sku=${encodeURIComponent(Sku)}&` : '';
  const url = `${decksApiUrl}/media/GetMediaGallery?${hasSku}ProductId=${encodeURIComponent(ProductId)}&Scene7Folder=${encodeURIComponent(Scene7Folder)}`;
  return api.get(url);
};

export default getVisualizerImages;
