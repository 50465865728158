import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SqftEstimate from './SqftEstimate';
import CostEstimate from './CostEstimate';
import { EstimatorStyle } from './EstimatorStyles';
import { isConfigurationCartable } from '../../selectors';
import marketAvailabilitySelector from '../../selectors/marketAvailabilitySelector';

export class Estimator extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { customerInMarket, isCartable } = this.props;
    return customerInMarket && isCartable ? (
      <EstimatorStyle className="estimator" id="estimatorId">
        <SqftEstimate />
        <CostEstimate />
      </EstimatorStyle>
    ) : null;
  }
}

Estimator.propTypes = {
  customerInMarket: PropTypes.bool.isRequired,
  isCartable: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isCartable: isConfigurationCartable(state),
  customerInMarket: marketAvailabilitySelector(state),
});

export default connect(mapStateToProps)(Estimator);
