import identity from 'lodash/identity';
import * as types from '../../apps/actions/actionTypes';
import setEstimatedCost from './setEstimatedCost';
import setEstimatedSqft from './setEstimatedSqft';

const actions = {
  [types.SET_ESTIMATED_COST]: setEstimatedCost,
  [types.SET_ESTIMATED_SQFT]: setEstimatedSqft,
};

const initialState = {
  sqft: 0,
  cost: 0.0,
};

export default (state = initialState, action) => (actions[action.type] || identity)(state, action);
