/* eslint-disable prefer-promise-reject-errors */
import axios from 'axios';
import { Utils } from '../common';
import { assert, assertionPrefix } from '../apps/debug/assert';

const Api = () => {
  const groupName = `${assertionPrefix}:api`;
  const newBaseUrl = Utils.getUrlAddress(window.location.href).replace(
    /\/+$/g,
    '',
  );
  const baseUrl = `${newBaseUrl}/`;

  const axiosConfig = {
    baseUrl,
    headers: { Accept: 'application/json' },
  };

  const instance = axios.create(axiosConfig);

  instance.interceptors.request.use((request) => {
    console.groupEnd();
    return request;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      assert({
        condition: false,
        error: `${groupName}: Call to ${error.config.url} failed`,
        data: error
      });
      return Promise.reject({ ...error });
    }
  );

  instance.defaults.headers.post['Content-Type'] = 'application/json';

  return instance;
};

export default Api;
