import { createReducer } from '../utils';
import { SET_API_ERROR } from '../actions';

export const apiErrors = createReducer({}, {
  [SET_API_ERROR](state, action) {
    return [...state, { details: action.errors }];
  },
});

export default apiErrors;
