import { createReducer } from '../utils';
import { SET_SAGA_ERROR } from '../sagas/utils';

export const sagaErrors = createReducer({}, {
  [SET_SAGA_ERROR](state, action) {
    return [...state, { details: action.errors }];
  },
});

export default sagaErrors;
