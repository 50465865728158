import loadSubAppData from '../services/loadSubAppData';
import matchColorToChoices from './colorMatch';
import { setSubAppData } from '../actions';

const hardwareSubAppLifecycle = (
  decksApiBaseUrl,
  assemblyId,
  storeId,
  selectedColor,
) => (dispatch, getState) => {
  loadSubAppData(decksApiBaseUrl, assemblyId, storeId).then((response) => response.data)
    .then((data) => {
      dispatch(setSubAppData({ ...data }));
      matchColorToChoices(selectedColor)(dispatch, getState);
    });
};

export default hardwareSubAppLifecycle;
