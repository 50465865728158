import React,{useState,useEffect} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ErrorMessage from './ErrorMessage';
import { updatePath } from '../actions/pathActions';
import dimensionAvailabilityEvaluatorFactory from '../../businessLogic/choiceAvailability/dimensionAvailabilityEvaluatorFactory';
import marketAvailabilitySelector from '../../selectors/marketAvailabilitySelector';

const Dropdown = (props) => {

  const [state,setState] = useState({active: false});

  useEffect(() => {
    // Runs after EVERY rendering
    const availableChoices = props.choices.filter(choice => props.isDimensionChoiceAvailable(choice.id));

    const selectedChoice = props.choices.find(c => c.isSelected);
    const selectedChoiceUnavailable = selectedChoice && !availableChoices.some(c => c.id === selectedChoice.id);
   
   if (availableChoices.length > 0 && selectedChoiceUnavailable) {
     changeSelection(availableChoices[0]);
   }
  });  

    



  const changeSelection = (choice) => {
    choiceChange(choice.id, choice.shortLabel, props.isDimensionChoiceAvailable(choice.id));
  }

  const getSelectedDimension = ()=> {
    return props.choices.find((choice) => choice.isSelected);
  }

  const choiceChange = (e, choiceId, choiceValue, isAvailable) => {
    if (!isAvailable) return;
    if (!state.active) {
      setState({ active: true });
      return;
    }

    // close before evaluating
    e.stopPropagation();
    setState({ active: false });

    const currentChoice = props.choices.find(
      (choice) => choice.isSelected,
    );
    if (currentChoice && currentChoice.id === choiceId) {
      return;
    }

    props.onChoiceSelected(props.option.id, choiceId, choiceValue)
      .then(() => {
        props.onChoiceSelectedSuccess();
      });
  }

  const toggle = (e)=> {
    e.stopPropagation();
    if (!props.disableOption) {
      // eslint-disable-next-line react/no-access-state-in-setstate
      setState({ active: !state.active });
    }
  }

  const blur = (e)=> {
    e.stopPropagation();
    setState({ active: false });
  }

 const  displayDropdown = ()=> {
    const selectedChoice = getSelectedDimension();
    const disabledClass = props.disableOption ? 'disabled' : '';
    const disabledStyle = props.disableOption ? { color: 'grey' } : {};
    return (
      <div>
        <div className="attribute_label">
          {props.option.shortLabel}
        </div>
        {!selectedChoice && (
        <ErrorMessage message="Please select from available option" />
        )}
        <a
          className={`customLabel drop-down__title ${disabledClass}`}
          onClick={(e) => toggle(e)}
          role="presentation"
        >
          <span
            id="selectlistOption2"
            className="custom select"
            style={disabledStyle}
          >
            {selectedChoice && selectedChoice.shortLabel}
            {!selectedChoice && 'Select a Size'}
          </span>
        </a>
        <div
          className={
                        window.prerender
                          ? 'drop-down__content-wrapper--open'
                          : 'drop-down__content-wrapper'
                    }
        >
          <ul
            className="listOptions drop-down__list"
            id="labellistOption2"
          >
            {props.choices.map((choice) => (
              <li
                role="presentation"
                onClick={(e) => choiceChange(
                  e,
                  choice.id,
                  choice.shortLabel,
                  props.isDimensionChoiceAvailable(
                    choice.id,
                  ),
                )}
                key={choice.id}
              >
                <span className="drop-down__hover-effect">
                  <a
                    className={
                                            props.isDimensionChoiceAvailable(
                                              choice.id,
                                            )
                                              ? 'enabled'
                                              : 'disabled'
                                        }
                    data-itemid="203207925"
                  >
                    {choice.shortLabel}
                  </a>
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }


    const active = state.active ? 'active' : '';
    return (
      <div
        className={`sku_variant product_sku_Overlay_ListBoxes_2 pip-drop-down__header ${active}`}
        tabIndex="-1"
        role="presentation"
      >
        {!props.disableOption && displayDropdown()}
      </div>
    );
  
}

Dropdown.propTypes = {
  choices: PropTypes.array.isRequired,
  option: PropTypes.object.isRequired,
  onChoiceSelected: PropTypes.func.isRequired,
  onChoiceSelectedSuccess: PropTypes.func.isRequired,
  disableOption: PropTypes.bool.isRequired,
  isDimensionChoiceAvailable: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { option }) => {
  const { configurator } = state;
  const isDimensionChoiceAvailable = dimensionAvailabilityEvaluatorFactory(
    state,
  );
  const sortedChoices = option.choices
    .map((it) => configurator.choices[it])
    .sort((a, b) => a.sequence - b.sequence);
  return {
    choices: sortedChoices,
    disableOption: !marketAvailabilitySelector(state),
    isDimensionChoiceAvailable,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChoiceSelectedSuccess: () => {
    dispatch(updatePath());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dropdown);
