import { createReducer } from '../utils';
import { ACTIVATE_SPINNER_ATC, DISABLE_SPINNER_ATC } from '../actions';

export const spinnerATC = createReducer(
  {},
  {
    [ACTIVATE_SPINNER_ATC](state, action) {
      return {
        ...state,
        status: action.status,
      };
    },
    [DISABLE_SPINNER_ATC](state, action) {
      return {
        ...state,
        status: action.status,
      };
    },
  },
);

export default spinnerATC;
