import { createReducer } from '../utils';
import { UPDATE_PRODUCT_DELIVERY } from '../actions';

const productDeliveryReducer = createReducer(
  {},
  {
    [UPDATE_PRODUCT_DELIVERY](state, { startDate, endDate }) {
      return {
        ...state,
        startDate,
        endDate,
      };
    },
  },
);

export default productDeliveryReducer;
