import React from 'react';
import PropTypes from 'prop-types';

const Tiles = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 36 27" version="1.1">
    <g id="PIP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Assets"
        transform="translate(-440.000000, -584.000000)"
        fill="#F96302"
        stroke="#FFFFFF"
      >
        <g id="Group-5" transform="translate(437.000000, 585.000000)">
          <polygon
            id="Path-15-Copy-2"
            transform="translate(12.294229, 16.000000) rotate(-60.000000) translate(-12.294229, -16.000000) "
            points="12.0307331 7 3.29422863 19.281375 12.5260693 25 21.2942286 12.696086"
          />
          <polygon
            id="Path-15"
            points="29.0307331 0 20.2942286 12.281375 29.5260693 18 38.2942286 5.69608596"
          />
          <rect id="Rectangle-22" x="14.2942286" y="3" width="12" height="15" />
        </g>
      </g>
    </g>
  </svg>
);

Tiles.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

Tiles.defaultProps = {
  width: '36px',
  height: '27px',
};

export default Tiles;
