import { saveConfiguration } from '../../../services/configurationApi';
import { quoteCenterOffering } from './quoteCenterOffering';

async function saveItemConfiguration(apis, siteId, storeId, item, hiddenChoices) {
  try {
    const {
      storeIdChoice, externalProductIdChoice, externalVendorIdChoice, externalPartNumber, deckingSourceChoice,
    } = hiddenChoices;

    const {
      productId,
      sku,
      quantity,
      beautyShotUrl,
      choices,
    } = item;

    const { data } = await quoteCenterOffering(apis.decksApiUrl, storeId, sku);

    if (data.data.length === 0 && data.errors.length > 0) {
      console.log(`Error getting Quote Center offering for - Store: ${storeId}, sku: ${sku}, Error: ${data.errors[0]}`);
      return null;
    }

    if (data.data.length === 0) {
      console.log(`No Quote Center offerings returned for - Store: ${storeId}, sku: ${sku}`);
      return null;
    }

    const offering = data.data[0];

    // Add Store Id
    choices.push({ choiceValue: storeIdChoice.choice.id, optionId: storeIdChoice.optionId, choiceName: storeId });

    // Add External Product Id
    choices.push({ choiceValue: externalProductIdChoice.choice.id, optionId: externalProductIdChoice.optionId, choiceName: sku });

    // Add External Vendor Id
    choices.push({ choiceValue: externalVendorIdChoice.choice.id, optionId: externalVendorIdChoice.optionId, choiceName: offering.mvendorNumber });

    // Add External Vendor Part Number
    choices.push({ choiceValue: externalPartNumber.choice.id, optionId: externalPartNumber.optionId, choiceName: offering.vendorPartNumber });

    // Add Decking Source
    choices.push({ choiceValue: deckingSourceChoice.choice.id, optionId: deckingSourceChoice.optionId, choiceName: 'Configuration_Origin' });

    const payload = {
      siteId, mainImageUrl: beautyShotUrl, productId, selections: choices,
    };
    // eslint-disable-next-line no-console
    // console.log(`payload: ${JSON.stringify(payload)}`);
    const result = await saveConfiguration(apis.configApiUrl, payload);

    if (result.data.ResponseCode !== 200) {
      console.log(`Create configuration for product Id ${productId} failed - (${result.data.ResponseCode} ${result.data.Message})`);
      return null;
    }

    return {
      productId,
      configurationGuid: result.data.ConfigurationGuid,
      mVendor: offering.mvendorNumber,
      quantity,
      unitPrice: offering.price,
    };
  } catch (error) {
    console.log(`Error creating item configuration for - product Id ${item.productId}  Store: ${storeId}, sku: ${item.sku}, Error: ${JSON.stringify(error)}`);
    return null;
  }
}

export default saveItemConfiguration;
