import { createSelector } from 'reselect';
import hardwareProductsSelector from './hardwareProductsSelector';
import hardwareIdsSelector from './hardwareIdsSelector';

const getHiddenOptionIds = (products, allIds) => {
  const exampleHardware = products[allIds[0]];

  const vendorOption = exampleHardware.options.find((o) => o.internalName === 'External_VendorId');
  const storeOption = exampleHardware.options.find((o) => o.internalName === 'Decking_ExternalStoreId');
  const productOption = exampleHardware.options.find((o) => o.internalName === 'Decking_ExternalProductId');
  const partNumberOption = exampleHardware.options.find((o) => o.internalName === 'External_VendorPartNumber');

  const foundAllOptions = vendorOption && storeOption && productOption && partNumberOption;
  if (foundAllOptions) {
    return {
      vendorOptionId: vendorOption.id,
      storeOptionId: storeOption.id,
      productOptionId: productOption.id,
      partNumberOptionId: partNumberOption.id,
    };
  }

  return { vendorOptionId: '0' };
};

export const hiddenOptionIdsSelector = createSelector(
  (state) => hardwareProductsSelector(state),
  (state) => hardwareIdsSelector(state),
  (products, allIds) => {
    if (products && allIds) {
      return getHiddenOptionIds(products, allIds);
    }
    return {};
  },
);

export default hiddenOptionIdsSelector;
