/* eslint-disable no-alert */
/* globals injectedConfiguratorSettings */
import configurationSettings from '../selectors/configuratorSettings';

export default function failedToATC(message, state) {
  const injectedConfiguratorSettings = configurationSettings(state);
  alert(`We are currently unable to add this to your cart.\n 
    REASON: ${message}\n
    Please call ${injectedConfiguratorSettings.customerSupportPhoneNum} for assistance. `);
}
