import memoize from 'lodash/memoize';
import productsSelector from '../selectors/productsSelector';

const getActiveProducts = (products, activeIds) => {
  const calculatorInputs = [];
  activeIds.forEach((element) => {
    calculatorInputs.push({
      productId: products[element].productId,
      areaCoverage: products[element].attributes.areaCoverage,
      coverageType: products[element].attributes.coverageType,
    });
  });
  return calculatorInputs;
};
const isAssociatedWithActiveEdge = (product, edge) => {
  if (product.attributes && product.attributes.associatedChoicesInternalNames) {
    return product.attributes.associatedChoicesInternalNames.toLowerCase() === edge.toLowerCase();
  }
  return false;
};

export const activeProductIdsProjectorFactory = (selectedEdge) => (state) => {
  const products = productsSelector(state);
  const productIds = Object.keys(products);
  const activeIds = productIds.filter((id) => isAssociatedWithActiveEdge(products[id], selectedEdge));
  return getActiveProducts(products, activeIds);
};

const memoizedFactory = memoize(activeProductIdsProjectorFactory);

export default memoizedFactory;
