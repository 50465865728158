import { addToCartAnalytics } from '../../../analytics';

export async function sendKitToAnalytics(kitItems, shippingMode = 'boss') {
  const items = kitItems.map((kitItem) => ({
    item: {
      sku: kitItem.omsId,
      quantity: kitItem.quantity,
      unitPrice: kitItem.unitPrice,
      fulfillmentMethod: kitItem.fulfillmentMethod,
    },
  }));
  try {
    await addToCartAnalytics(items, shippingMode);
    // eslint-disable-next-line no-console
  } catch (err) {
    console.log(`Error : ${JSON.stringify(err)}  sending the analytics kart kititem:${JSON.stringify(kitItems)}`);
  }
}
export default sendKitToAnalytics;
