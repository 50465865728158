
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import updateQuantity from '../actions/updateQuantity';
import showQuantityWarning from '../../businessLogic/showQuantityWarning';
import inventoryModeSelector from '../../selectors/catalog/inventoryModeSelector';
import availableStockSelector from '../../selectors/catalog/availableStockSelector';
import { quantitySelector } from '../../selectors';
import marketAvailabilitySelector from '../../selectors/marketAvailabilitySelector';
import getConfiguratorSettings from '../../selectors/configuratorSettings';
export class QuantityTemplate extends React.Component {
  componentDidMount() {
    if (this.props?.config?.quantity) {
      this.props.updateQuantity(this.props.config.quantity);
    } else {
        this.props.updateQuantity(1);
    }
  }

  onBlur(e) {
    if (e.currentTarget.value === '') {
      this.props.updateQuantity(1);
    }
  }

  increaseCount() {
    if (this.props.disableOption) return;
    const newQuantity =
      this.props.quantity < 9999 ? parseInt(this.props.quantity, 10) + 1 : 1;
    this.props.updateQuantity(newQuantity);
  }

  decreaseCount() {
    if (this.props.disableOption) return;
    const newQuantity =
      this.props.quantity > 1 ? parseInt(this.props.quantity, 10) - 1 : 1;
    this.props.updateQuantity(newQuantity);
  }

  textChange(e) {
    if (e.currentTarget.value.length > 4) {
      return;
    }

    let newQuantity = parseInt(e.currentTarget.value, 10);    
    if(newQuantity === 0) { newQuantity = 1; }
    this.props.updateQuantity(newQuantity);
  }

  render() {
    const disabledStyle = this.props.disableOption ? 'disabled' : '';
    return (
      <BuyBoxQuantityFlex className="buybox__quantity-flex">
        <BuyBoxQuantity
          className="buybox__quantity u__clearfix fsrVisible"
          id="buybox__quantity"
        >
          <BuyBoxQuantityWrapper className="quantity quantity-section-wrapper u__clearfix fsrVisible">
            <BuyboxQuantityLabel htmlFor="buybox__quantity__input">
              Quantity
            </BuyboxQuantityLabel>
            <div className="quantity-field-wrapper u__clearfix">
              <div
                className={`quantity-btn decrement ${disabledStyle}`}
                role="presentation"
                onClick={() => this.decreaseCount()}
                id="blah"
              >
                -
              </div>
              <QuantityBox
                id="buybox__quantity__input"
                className="quantity-input fsrVisible "
                type="number"
                value={this.props.quantity}
                min="0"
                max="9999"
                maxLength="4"
                inputMode="numeric"
                pattern="[0-9]*"
                onChange={e => this.textChange(e)}
                onBlur={e => this.onBlur(e)}
                disabled={this.props.disableOption}
                showWarning={this.props.showWarning}
              />
              <div
                className={`quantity-btn increment ${disabledStyle}`}
                onClick={() => this.increaseCount()}
                role="presentation"
              >
                +
              </div>
            </div>
          </BuyBoxQuantityWrapper>
        </BuyBoxQuantity>
        <div
          className="buybox__in-store-assembly"
          id="buybox__in-store-assembly"
        />
      </BuyBoxQuantityFlex>
    );
  }
}

const BuyBoxQuantityFlex = styled.div`
  margin-top: 0 !important;

  @media (min-width: 768px) {
    margin-top: 25px;
  }
`;

const BuyBoxQuantity = styled.div`
  margin-bottom: 20px !important;
`;

const BuyBoxQuantityWrapper = styled.div`
  flex-direction: column !important;

  @media (min-width: 768px) {
    flex-direction: row !important;
  }
`;

const QuantityBox = styled.input`
  width: 80px !important;
  ${props =>
    props.showWarning &&
    css`
      &&& {
        color: #8f6e2e;
        border-bottom-color: goldenrod;
        border-bottom-style: solid;
        border-bottom-width: 5px;
      }
    `}
`;

const BuyboxQuantityLabel = styled.label`
  margin-right: 25px !important;
  line-height: normal !important;
  font-size: 1.4rem !important;
  margin-bottom: 10px !important;
  color: #333 !important;
  @media (min-width: 768px) {
    font-size: 1.8rem !important;
    line-height: 42px !important;
    margin-bottom: 0 !important;
    color: #666 !important;
  }
`;

QuantityTemplate.propTypes = {
  quantity: PropTypes.number.isRequired,
  updateQuantity: PropTypes.func.isRequired,
  disableOption: PropTypes.bool.isRequired,
  showWarning: PropTypes.bool.isRequired,
};

const mapStateToProp = state => {
  
  const quantity = quantitySelector(state);
  const isInMarket = marketAvailabilitySelector(state);
  const fulfillment = inventoryModeSelector(state);
  const maxQuantity = availableStockSelector(state);
  const showWarning = showQuantityWarning(fulfillment, maxQuantity, quantity);
  const config = getConfiguratorSettings(state);
  return { quantity, disableOption: !isInMarket, showWarning,config };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateQuantity }, dispatch);

export default connect(
  mapStateToProp,
  mapDispatchToProps,
)(QuantityTemplate);
