import { createSelector } from 'reselect';
import selectedProductSkuDataSelector from './selectedProductSkuDataSelector';

export const displayPriceSelector = createSelector(
  selectedProductSkuDataSelector,
  (theCatalog) => {
    if (!theCatalog || !theCatalog.price) return 0.0;

    return theCatalog.price;
  },
);

export default displayPriceSelector;
