import React from 'react';
import { Provider } from 'react-redux';
import store from '../../store/globalStore';
import { initDecking } from '../../actions/index';
import 'regenerator-runtime/runtime';
import PIP from '../pip';
import '../../decking-pipfigurator.scss';

store.dispatch(initDecking());

// eslint-disable-next-line react/prefer-stateless-function
class App extends React.Component {

  render() {
    // console.log('rendering decking configurator');
    return (
      <Provider store={store}>
        <div className="App">
          <PIP />
        </div>
      </Provider>
    );
  }
}

export { App as DeckingConfigurator };
