/* eslint-disable no-undef */

// Timeout in ms
const defaultTimeOut = 800;

export class Utils {
  static parseUrl(url) {
    let link = document.createElement('a');

    link.setAttribute('href', url);

    const retValue = {
      port: link.port,
      hostname: link.hostname,
      pathname: link.pathname,
      protocol: link.protocol,
      search: link.search,
    };

    link = null;

    return retValue;
  }

  static getUrlAddress(url) {
    const newUrl = Utils.parseUrl(url);
    return `${newUrl.protocol}//${newUrl.hostname}:${newUrl.port}`;
  }

  static defaultTimeOut() {
    return defaultTimeOut;
  }

  static getStoreIdFromStateOrCookie(state, getThdStore) {
    if (state.checkout && state.checkout.localStores) {
      const store = state.checkout.localStores.find((s) => s.isSelected);
      if (store) { return store.storeId; }
    }
    return getThdStore().storeNumber || 121;
  }

  static getStoreNameFromStateOrCookie(state, getThdStore) {
    if (state.checkout && state.checkout.localStores) {
      const store = state.checkout.localStores.find((s) => s.isSelected);
      if (store) { return store.name; }
    }
    return getThdStore().storeName || 'Cumberland #121';
  }

  static async sendAnalyticsEvent(thdAnalyticsEvent, timeOut = defaultTimeOut) {
    return new Promise((resolve) => {
      if (!window.digitalData.event) {
        window.digitalData.event = [];
      }
      window.digitalData.event.push(thdAnalyticsEvent);
      window.setTimeout(resolve(), timeOut);
    });
  }
}

const exportObj = {
  Utils,
};

export default exportObj;
