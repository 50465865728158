export const buildBaseEventData = (primaryCategory, eventName) => {
  const baseObject = {
    category: { primaryCategory },
    eventInfo: { eventName },
  };

  return baseObject;
};

export const buildBcomAnalyticsEvent = (primaryCategory, eventName, { omsId, productName, brand }) => {
  const eventData = buildBaseEventData(primaryCategory, eventName);

  if (omsId) {
    eventData.item = [{
      productInfo: {
        sku: `${omsId}`,
        productName,
        manufacturer: `${brand}`,
        productTYpe: 'mechandise',
      },
    }];
  }

  return eventData;
};
