import renderStyledComponentsToDOM from './renderStyledComponentsToDOM';
import setPrerenderStatus from './setPrerenderStatus';

/**
 * Indicates that the page has finished rendering, and is ready to be cached by
 * Prerender.io
 *
 * @param   {number|boolean} status
 *          A boolean indicating whether the page rendered successfully *or* an
 *          HTTP status code.
 * @returns {Promise<number|boolean>}
 *          A promise that resolves when the finalization is complete.
 */
export default function finalize(status = true) {
  return new Promise((resolve) => {
    window.requestAnimationFrame(() => {
      renderStyledComponentsToDOM();
      setPrerenderStatus(status);
      window.prerenderReady = (status === true || status === 200 || status === 304);
      resolve(status);
    });
  });
}
