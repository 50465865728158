import styled from 'styled-components';

export const EstimatorStyle = styled.div`
  box-sizing: border-box;
  width: inherit;
  border: 1px solid #ccc;
  font-family: 'helvetica-neue-light', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  padding: 14px;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  align-items: flex-start;

  &:after,
  &:before {
    bottom: 100%;
    left: 97px;
    border: solid transparent;
    content: ' ';
    height: 0;
    border-width: 0 18px 13px 18px;
    position: absolute;
    pointer-events: none;

    @media (min-width: 768px) {
      left: 50%;
    }
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    margin-left: -17px;
    border-width: 0 17px 12px 17px;
  }
  &:before {
    border-color: rgba(204, 204, 204, 0);
    border-bottom-color: #ccc;
    margin-left: -18px;
  }

  @media (min-width: 768px) {
    width: 382px;
  }
`;

export const SqftEstimateStyle = styled.div`
  font-size: 15px;
  flex: 0 0 172px;
  line-height: 18px;
  padding-right: 12px;
`;

export const CalculatedDisplayStyles = styled.span`
  white-space: nowrap;
  font-weight: bold;
`;

export const CostEstimateStyle = styled.div`
  flex: 0 1 auto;
  padding-left: 12px;
`;

export const HardwareSubtext = styled.p`
  font-size: 11px;
`;

export const CostEstimateStylePriceHeaderStyle = styled.h4`
  font-size: 25px;
  color: #f96302;
  line-height: 25px;
  font-weight: normal;
  margin-bottom: 6px;
`;

export const EstimatedTitleStyles = styled.span`
  font-size: 15px;
  color: #000;
`;
