import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  CostEstimateStyle,
  CostEstimateStylePriceHeaderStyle,
  HardwareSubtext,
  EstimatedTitleStyles,
} from './EstimatorStyles';
import { getTotalHardwarePriceSelector, allHardwareIsAvailableSelector } from '../../selectors';
import { getEstimationCost } from '../../selectors/getEstimationCost';
import { availableStockSelector } from '../../selectors/catalog/availableStockSelector';

export const CostEstimate = (props) => {
  const { totalCost } = props;
  const cost = totalCost.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  const { showHardWareDisclaimer } = props;
  return (
    <CostEstimateStyle>
      <CostEstimateStylePriceHeaderStyle>
        <span id="cost">{`$${cost}`}</span>
      </CostEstimateStylePriceHeaderStyle>
      <EstimatedTitleStyles>estimated total</EstimatedTitleStyles>
      {showHardWareDisclaimer ? (
        <HardwareSubtext id="hardware-subtext">
          (including installation hardware)
        </HardwareSubtext>
      ) : null}
    </CostEstimateStyle>
  );
};

CostEstimate.propTypes = {
  showHardWareDisclaimer: PropTypes.bool.isRequired,
  totalCost: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  const availableStock = availableStockSelector(state);
  const calculatedBaseCost = getEstimationCost(state);
  const subAppHardwareAvailable = allHardwareIsAvailableSelector(state);
  const hardwarePrice = subAppHardwareAvailable && (availableStock <= 0)
    ? getTotalHardwarePriceSelector(state)
    : 0;

  const showHardWareDisclaimer = hardwarePrice !== 0 || false;
  const totalCost = calculatedBaseCost + hardwarePrice;

  return {
    totalCost,
    showHardWareDisclaimer,
  };
};

export default connect(mapStateToProps)(CostEstimate);
