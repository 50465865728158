import { createAction } from './createAction';

export const GET_MEDIA_GALLERY_IMAGES = 'GET_MEDIA_GALLERY_IMAGES';

export const getMediaGalleryImages = createAction(GET_MEDIA_GALLERY_IMAGES, 'decksApiUrl', 'payload');

export const GET_MEDIA_GALLERY_IMAGES_SUCCESS = 'GET_MEDIA_GALLERY_IMAGES_SUCCESS';

export const setMediaGalleryImages = createAction(GET_MEDIA_GALLERY_IMAGES_SUCCESS, 'imageList');

export const GET_MEDIA_GALLERY_IMAGES_FAILED = 'GET_MEDIA_GALLERY_IMAGES_FAILED';

export const setMediaGalleryImagesFailed = createAction(
  GET_MEDIA_GALLERY_IMAGES_FAILED,
  {},
);

export const MEDIA_GALLERY_IMAGES_READY = 'MEDIA_GALLERY_IMAGES_READY';
export const mediaGalleryImagesReady = createAction(MEDIA_GALLERY_IMAGES_READY);