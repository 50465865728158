import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import {
  ModalContainer,
  ModalContentContainer,
  ModalContent,
  ModalMedia,
  ModalMediaVideo,
} from './styles';

import InfoLink from '../../../../../components/InfoLink';
import Markdown from '../../../../../components/Markdown';
// import LoadableVideoPlayer from './loadableVideoPlayer';
import { getTooltip } from '../../../services/tooltipApi';

export const DeckInfoLink = (props) => {
  const [toolTipData, setToolTipData] = useState({});
  const { title, term } = props;
  const { definition, mediaUrl, videoTag } = toolTipData;

  useEffect(() => {
    getTooltip(term)
      .then((data) => data.data)
      .then((data) => setToolTipData(data))
      .catch((error) => {
        console.log(`${error} for term ${term}`); // eslint-disable-line no-console
      });
  }, []);

  const RenderVideo = (path) => {
    const videoJsOptions = {
      autoplay: false,
      controls: true,
      sources: [
        {
          src: path,
          type: 'video/mp4',
        },
      ],
    };

    return (
      <div className="modalMediaVideo">
        {/* <LoadableVideoPlayer {...videoJsOptions} /> */}
      </div>
    );
  };

  return isEmpty(toolTipData) || !definition ? null : (
    <InfoLink {...props} title={title}>
      <ModalContainer id="ModalContainer">
        <ModalContentContainer id="ModalContentContainer">
          {videoTag && (
            <ModalMediaVideo className="ModalMediaVideo">
              {RenderVideo(videoTag)}
            </ModalMediaVideo>
          )}
          <ModalContent id="ModalContent">
            <Markdown
              id="MarkdownComponent"
              source={definition}
            />
          </ModalContent>
          {mediaUrl && !videoTag && (
            <ModalMedia className="ModalMediaImage">
              <img src={mediaUrl} alt={`Tooltip for ${title}`} height="100%" width="100%" />
            </ModalMedia>
          )}
        </ModalContentContainer>
      </ModalContainer>
    </InfoLink>
  );
};

DeckInfoLink.propTypes = {
  toolTipData: PropTypes.object,
  term: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

DeckInfoLink.defaultProps = {
  toolTipData: {},
};

export default styled(DeckInfoLink)`
    font-size: 12px;
`;
