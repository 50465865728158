import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const HardwareProducts = styled.ul`
      display: grid;
    grid-auto-rows: 1fr;
`;

export const HardwareProductsItem = styled.li`
  margin-bottom: 20px;
  list-style: none;
`;
