import memoize from 'lodash/memoize';
import optionsSelector from '../selectors/optionsSelector';
import choicesSelector from '../selectors/choicesSelector';

export const choicesForOptionProjector = (optionId) => memoize((state) => {
  const theOption = optionsSelector(state)[optionId];
  if (!theOption) return [];

  const choices = choicesSelector(state);
  const relatedChoices = theOption.choices.map((id) => choices[id]);
  return relatedChoices;
});

export default memoize(choicesForOptionProjector);
