import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ErrorMessage from './ErrorMessage';
import  './DefaultTemplate.scss';
import updateAfterSelection from '../actions/updateAfterSelection';
import edgeAvailabilityEvalutorFactory from '../../businessLogic/choiceAvailability/edgeAvailabilityEvalutorFactory';
import marketAvailabilitySelector from '../../selectors/marketAvailabilitySelector';

class DefaultTemplate extends React.Component {

  componentDidUpdate() {
    const availableChoices = this.props.choices.filter(choice => this.props.isEdgeAvailable(choice.id));

     const selectedChoice = this.props.choices.find(c => c.isSelected);
     const selectedChoiceUnavailable = selectedChoice && !availableChoices.some(c => c.id === selectedChoice.id);

    if (availableChoices.length > 0 && selectedChoiceUnavailable) {
      this.changeSelection(availableChoices[0]);
    }
  }

  changeSelection(choice) {
    this.choiceChange(choice.id, choice.shortLabel, this.props.isEdgeAvailable(choice.id));
  }

  getSelectedChoice() {
    const selected = this.props.choices.find((choice) => choice.isSelected);
    return selected;
  }

  choiceChange(choiceId, choiceValue, isAvailable) {
    if (!isAvailable) return;
    const currentChoice = this.props.choices.find((choice) => choice.isSelected);
    if (currentChoice && currentChoice.id === choiceId) {
      return;
    }

    this.props
      .onChoiceSelected(this.props.option.id, choiceId, choiceValue)
      .then(() => this.props.onChoiceSelectedSuccess());
  }

  render() {
    const selectedChoice = this.getSelectedChoice();
    return (
      <div className="defaultTemplate">
        <div>
          <div className="optionDescription" data-id="gcc-color-name">
            <div className="attribute_label">
              <span>
                {this.props.option.shortLabel}
                :
                {' '}
              </span>
              {!selectedChoice && !this.props.disableOption && (
                <ErrorMessage message="Please select from available choices" />
              )}
              <span id="edge-label" className="u__bold">
                {selectedChoice && selectedChoice.value}
              </span>
            </div>
          </div>

          <div className="options" xyz='yes'>
            {this.props.choices.map((choice) => {
              const selected = choice.isSelected ? 'selected' : '';
              const disabled = choice.isDisabled || !this.props.isEdgeAvailable(choice.id)
                ? 'disabled'
                : '';
              return (
                <div
                  key={choice.id}
                  onClick={() => this.choiceChange(
                    choice.id,
                    choice.shortLabel,
                    this.props.isEdgeAvailable(choice.id),
                  )}
                  id={choice.shortLabel}
                  className={`option ${selected} ${disabled}`}
                  role="presentation"
                >
                  <img
                    alt={choice.longLabel}
                    title={choice.longLabel}
                    src={`${choice.mediaUrl}?fmt=jpeg&fit=constrain,1&hei=100`}
                  />
                  <span className='label'>{choice.shortLabel}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

DefaultTemplate.propTypes = {
  choices: PropTypes.array.isRequired,
  option: PropTypes.object.isRequired,
  onChoiceSelected: PropTypes.func.isRequired,
  onChoiceSelectedSuccess: PropTypes.func.isRequired,
  disableOption: PropTypes.bool.isRequired,
  isEdgeAvailable: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { option }) => {
  const { configurator } = state;

  const isEdgeAvailable = edgeAvailabilityEvalutorFactory(state);

  return {
    choices: option.choices.map((it) => configurator.choices[it]),
    disableOption: !marketAvailabilitySelector(state),
    isEdgeAvailable,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChoiceSelectedSuccess: () => {
    dispatch(updateAfterSelection());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DefaultTemplate);
