import { createReducer } from '../utils';
import {
  GET_MEDIA_GALLERY_IMAGES_SUCCESS,
  GET_MEDIA_GALLERY_IMAGES_FAILED,
} from '../actions';

const failedMediaGalleryImages = [
  {
    key: 0,
    thumbnail:
      'https://custom.homedepot.com/is/image/blinds/missingchoices?fmt=jpeg&fit=constrain,1&hei=117',
    primaryLink:
      'https://custom.homedepot.com/is/image/blinds/missingchoices?fmt=jpeg&fit=constrain,1&hei=1000',
    mediaType: 'IMAGE',
  },
];

export const imageGallery = createReducer(
  {},
  {
    [GET_MEDIA_GALLERY_IMAGES_FAILED](state) {
      return {
        ...state,
        mediaImages: failedMediaGalleryImages,
      };
    },
    [GET_MEDIA_GALLERY_IMAGES_SUCCESS](state, action) {
      return {
        ...state,
        mediaImages: action.imageList,
      };
    },
  },
);

export default imageGallery;
